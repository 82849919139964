<template>
  <div class="sso-login-container">
    <p>SSO 로그인 처리 중...</p>
  </div>
</template>

<script>
import apiClient from "@/apiClient"; // 로그인 처리를 위한 API 클라이언트
import { useToast } from "vue-toastification";

export default {
  name: "SSOLogin",
  setup() {
    const toast = useToast();
    return { toast };
  },
  async created() {
    const encodedUserid = this.$route.params.userid; // URL 파라미터로 받은 userid

    // type = 'A' 면 프로젝트등록을 경우 list 로 들어오는 경우
    // type = 'B' 면 세금계산서 관련
    // type = 'N' 면 일반 마이즈 직원 접속 시

    const type = this.$route.params.type;
    const conid = this.$route.params.conid;

    console.log("type===" + type);
    console.log("conid===" + conid);

    if (encodedUserid) {
      try {
        // userid를 사용한 로그인 처리 (예시)
        const response = await apiClient.post("/api/Auth/sso-login", {
          UserId: encodedUserid,
          LoginType: type,
        });

        if (response.status === 200) {
          // 로그인 성공 시 로컬 스토리지에 사용자 정보 저장
          const user = response.data.user;
          localStorage.setItem("userid", user.UserId.toLowerCase());
          localStorage.setItem("usertype", user.UserType);
          localStorage.setItem("username", user.AdminName);
          localStorage.setItem("companyid", user.CompanyId);
          localStorage.setItem("userpermission", user.UserPermission);

          this.$store.dispatch("updateUserName", user.AdminName); // Vuex 스토어와 localStorage에 저장
          this.$store.dispatch("updateUserType", user.UserType); // Vuex 스토어와 localStorage에 저장

          // 외주업체라면
          if (localStorage.getItem("usertype") == "C" && type === "A") {
            console.log("여기===" + type);
            //this.$router.push("/projects/companylist");
            this.$router.push("/notices/list");
          }

          // 외주업체라면
          if (localStorage.getItem("usertype") == "C" && type === "C") {
            console.log("여기===" + type);
            this.$router.push("/projects/companyview/" + conid);
          }

          // 마이즈 라면, 경영지원팀
          if (localStorage.getItem("usertype") == "M" && type === "B") {
            console.log("여기여기===" + type);
            this.$router.push("/projects/view/" + conid);
          }
          // 마이즈 라면, PM
          if (localStorage.getItem("usertype") == "M" && type === "F") {
            console.log("여기여기===" + type);
            this.$router.push("/projects/view/" + conid);
          }
          // 마이즈 라면
          if (localStorage.getItem("usertype") == "M" && type === "N") {
            console.log("여기여기여기===" + type);
            this.$router.push("/projects/list");
          }

          // 마이즈 이고, 외주공사팀 일정에서 클릭할 경우
          if (localStorage.getItem("usertype") == "M" && type === "M0") {
            console.log("여기여기여기===" + type);

            try {
              const response = await apiClient.post(
                "/api/Construction/construction-id-info",
                {
                  LoginId: encodedUserid,
                  WrId: conid,
                }
              );

              if (response.status === 200) {
                const data = JSON.parse(response.data);

                this.info = Array.isArray(data.data) ? data.data : [];
                console.log("새로 얻은 newconiwwwd:", this.info[0].id);
                this.$router.push(`/projects/view/${this.info[0].id}`);
              }
            } catch (error) {
              console.error("새로운 conid를 얻는 중 오류:", error);
              this.toast.error("새로운 정보를 불러오는데 실패했습니다.", {
                position: "top-center",
                timeout: 1500,
                closeOnClick: true,
                pauseOnHover: true,
                hideProgressBar: true,
                closeButton: false,
              });
              this.$router.push("/projects/list"); // 오류 시 기본 페이지로 리디렉션
            }
          }
          // 마이즈 이고, PM팀 일정에서 클릭할 경우
          if (localStorage.getItem("usertype") == "M" && type === "M1") {
            console.log("여기여기여기===" + type);

            try {
              const response = await apiClient.post(
                "/api/MizeConstruction/construction-id-info",
                {
                  LoginId: encodedUserid,
                  WrId: conid,
                }
              );

              if (response.status === 200) {
                const data = JSON.parse(response.data);

                this.info = Array.isArray(data.data) ? data.data : [];
                console.log("새로 얻은 newconiwwwd:", this.info[0].id);
                this.$router.push(`/projects/mizeview/${this.info[0].id}`);
              }
            } catch (error) {
              console.error("새로운 conid를 얻는 중 오류:", error);
              this.toast.error("새로운 정보를 불러오는데 실패했습니다.", {
                position: "top-center",
                timeout: 1500,
                closeOnClick: true,
                pauseOnHover: true,
                hideProgressBar: true,
                closeButton: false,
              });
              this.$router.push("/projects/mizelist"); // 오류 시 기본 페이지로 리디렉션
            }
          }
        }
      } catch (error) {
        console.error("SSO 로그인 처리 중 오류:", error);
        this.toast.error("로그인에 실패했습니다.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        this.$router.push("/login"); // 실패 시 로그인 페이지로 리디렉션
      }
    } else {
      this.toast.error("유효한 사용자 정보가 없습니다.", {
        position: "top-center", // 메시지 위치
        timeout: 1500, // 3초 동안 표시
        closeOnClick: true,
        pauseOnHover: true,
        hideProgressBar: true, // 진행 표시줄 숨기기
        closeButton: false, // X 버튼 숨기기
      });
      this.$router.push("/login"); // userid가 없으면 로그인 페이지로 리디렉션
    }
  },
};
</script>

<style scoped>
.sso-login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 18px;
  font-weight: bold;
}
</style>
