<template>
  <header class="header">
    <div class="header_top">
      <div class="header_topbox">
        <div class="header_top_column">
          <a href="https://mizeinc.co.kr" target="_blank">MIZE INC.</a>
        </div>
        <div class="header_top_column">
          <!-- Vue 데이터 바인딩해야 함 -->
          <span class="top_name">{{ username }} 님 </span>
          <router-link
            v-if="userType !== 'M'"
            to="/myinfo/view"
            @click="selectMenu('내정보')"
            >내 정보</router-link
          >
          <!-- 알림 아이콘 -->
          <div
            v-if="userType === 'M'"
            class="notification-container"
            ref="notificationContainer"
            @click="toggleNotification"
          >
            <span class="top_name">알림</span>
            <span class="notification-badge">
              {{ unreadCount }}
            </span>
          </div>
          <a href="#" @click.prevent="logout">로그아웃</a>
        </div>
        <!-- 알림 드롭다운 -->
        <div
          v-if="showNotifications"
          class="notification-dropdown"
          ref="notificationDropdown"
        >
          <ul v-if="notifications.length > 0">
            <li
              v-for="(notification, index) in notifications"
              :key="index"
              :class="{ 'disabled-notification': !notification.commentId }"
              @click="
                !notification.commentId
                  ? null
                  : handleNotificationClick(
                      notification.id,
                      notification.commentId,
                      notification.readId,
                      index,
                      notification.leftMenuName
                    )
              "
            >
              <div class="notification-item">
                <!-- 이미지 추가 -->
                <div>
                  <div class="notification-image">
                    <img
                      :src="
                        notification.userPhotoName
                          ? `https://mizecloud-partners.co.kr/employee_img/${notification.userPhotoName}`
                          : 'https://mizecloud-partners.co.kr/employee_img/default.png'
                      "
                    />
                  </div>
                  <div>
                    <div class="notification-content">
                      <span class="notification-user">
                        <span v-if="!notification.commentId">(삭제됨)</span>
                        {{ notification.userName }} 님이</span
                      >
                      <span>{{ notification.commentSubject }}</span>

                      <span>
                        {{
                          notification.parentId
                            ? "댓글에 댓글을 남겼습니다."
                            : "게시물에 댓글을 남겼습니다."
                        }}
                      </span>
                    </div>
                    <small>{{ notification.createdDtm }}</small>
                  </div>
                </div>
              </div>
              <button
                class="remove-btn"
                @click.stop="
                  handleNotificationAction(notification.readId, index)
                "
              >
                X
              </button>
            </li>
          </ul>
          <div v-else class="no-notifications">새로운 알림이 없습니다.</div>
          <router-link
            to="/alarmsnotices/list"
            class="view-all"
            @click="closeNotification"
            >+ 모두보기</router-link
          >
        </div>
      </div>
    </div>
    <nav class="header_bottom">
      <div class="header_bottombox">
        <!--이거 할때랑 스타일이 달라짐 체크해야 함-->
        <div class="logo">
          <img src="@/images/logo_w.png" @click="handleLogoClick" />
        </div>
        <div class="menu">
          <router-link
            v-if="userType === 'C'"
            to="/projects/companylist"
            :class="{ on: isActive('/projects') }"
            @click="refreshManagers(2)"
          >
            <img src="@/images/topic_thum_69.png" alt="" />프로젝트관리
          </router-link>
          <router-link
            v-if="userType === 'M'"
            to="/projects/list"
            :class="{ on: isActive('/projects') }"
            @click="refreshManagers(1)"
          >
            <img src="@/images/topic_thum_69.png" alt="" />프로젝트관리
          </router-link>
          <router-link
            v-if="userType === 'C'"
            to="/orders/list"
            :class="{ on: isActive('/orders') }"
            @click="refreshManagers(3)"
          >
            <img src="@/images/topic_thum_65.png" alt="" />수주관리
          </router-link>
          <router-link
            to="/accounting/list"
            :class="{ on: isActive('/accounting') }"
            @click="refreshManagers(4)"
          >
            <img src="@/images/topic_thum_55.png" alt="" />정산관리
          </router-link>
          <router-link
            v-if="userType === 'M'"
            to="/managers/list"
            :class="{ on: isActive('/managers') }"
            @click="refreshManagers(5)"
          >
            <img src="@/images/topic_thum_57.png" alt="" />담당자관리
          </router-link>
          <router-link
            v-if="userType === 'C'"
            to="/notices/list"
            :class="{ on: isActive('/notices') }"
            @click="refreshManagers(6)"
          >
            <img src="@/images/topic_thum_57.png" alt="" />공지사항
          </router-link>
          <router-link
            v-if="userType === 'M'"
            to="/notices/list"
            :class="{ on: isActive('/notices') }"
            @click="refreshManagers(7)"
          >
            <img src="@/images/topic_thum_57.png" alt="" />공지사항관리
          </router-link>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import apiClient from "@/apiClient"; // 설정 파일에서 가져온 axios 인스턴스
import { useToast } from "vue-toastification";

export default {
  data() {
    return {
      showNotifications: false, // 알림 드롭다운 표시 여부
      notifications: [], // 알림 데이터
      unreadCount: 0, // 읽지 않은 알림 개수
      alarmList: [], // 추가된 공지사항 데이터를 저장
    };
  },
  name: "MainHeader", // 다중 단어로 변경
  setup() {
    const toast = useToast();
    return { toast };
  },
  emits: ["menu-selected"], // 이벤트 정의 추가
  computed: {
    username() {
      return this.$store.getters.getUsername;
    },
    userType() {
      return this.$store.getters.getUsertype;
    },
    selectedMenu() {
      return this.$store.getters.getSelectedMenu; // Vuex 상태에서 현재 선택된 메뉴를 가져옴
    },
  },
  beforeUnmount() {
    // 이벤트 리스너 제거
    document.removeEventListener("click", this.handleOutsideClick);
  },
  async mounted() {
    this.checkAuth();

    this.fetchNotifications();
    const token_userid = localStorage.getItem("userid");

    if (token_userid) {
      const user = {
        UserId: token_userid,
      };

      // API 호출
      if (user.UserId) {
        try {
          const response = await apiClient.post("/api/User/user-my-info", user);

          if (response.status === 200) {
            const userName = response.data.user.AdminName;
            const usertype = response.data.user.UserType;

            this.$store.dispatch("updateUserName", userName); // Vuex 스토어와 localStorage에 저장
            this.$store.dispatch("updateUserType", usertype); // Vuex 스토어와 localStorage에 저장
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    } else {
      this.$router.push({ path: "/" });
    }

    document.addEventListener("click", this.handleOutsideClick);
  },
  methods: {
    handleNotificationClick(postId, commentId, readId, index, leftMenuName) {
      //alert(postId);
      if (!postId) {
        console.error("Post ID가 없습니다.");
        return;
      }
      //alert(leftMenuName);
      // 1. 읽음 처리
      this.handleNotificationAction(readId, index).then(() => {
        if (leftMenuName === "프로젝트목록 (마이즈)") {
          this.$router.push({
            path: `/projects/mizeview/${postId}`,
            query: { commentId },
          });
        }

        if (leftMenuName === "프로젝트목록 (외주)") {
          this.$router.push({
            path: `/projects/view/${postId}`,
            query: { commentId },
          });
        }

        if (leftMenuName === "담당자목록") {
          this.$router.push({
            path: `/managers/view/${postId}`,
            query: { commentId },
          });
        }

        if (leftMenuName === "공지사항목록") {
          this.$router.push({
            path: `/notices/view/${postId}`,
            query: { commentId },
          });
        }

        // 3. 페이지 로드 후 스크롤 처리
        this.$nextTick(() => {
          setTimeout(() => {
            const commentElement = document.getElementById(
              `comment-${commentId}`
            );
            if (commentElement) {
              commentElement.scrollIntoView({ behavior: "smooth" });
            }
          }, 500);
        });
      });
    },

    // 알림 읽음 및 제거 처리
    async handleNotificationAction(notificationId, index) {
      //alert(notificationId);
      try {
        const searchParam = {
          Page: 1,
          PageSize: 5,
          SearchConfirmStatus: "N", // 미확인 알림만
          SearchStatus: "A", // 전체 카테고리
          SearchBoardStatus: "", // 게시판 전체
          SearchEmployeeStatus: "A", // 모든 직원
          LoginUserId: localStorage.getItem("userid"),
          SearchUserId: "All",
          ReadId: notificationId,
        };

        const response = await apiClient.post(
          "/api/Alarm/all-alarm-list",
          searchParam
        );

        if (response.status === 200) {
          // 알림 배열에서 제거
          this.notifications.splice(index, 1);

          // 읽지 않은 알림 개수 감소
          this.unreadCount = Math.max(this.unreadCount - 1, 0);

          // 남은 알림이 5개 미만일 경우, 추가 데이터 가져오기
          if (
            this.notifications.length < 5 &&
            this.unreadCount > this.notifications.length
          ) {
            await this.fetchAdditionalNotifications(
              5 - this.notifications.length
            );
          }
        }
      } catch (error) {
        console.error(
          "Error marking notification as read and removing:",
          error
        );
      }
    },
    // 추가 알림 가져오기
    async fetchAdditionalNotifications(count) {
      try {
        const searchParam = {
          Page: Math.ceil((this.notifications.length + 1) / count), // 페이지 계산
          PageSize: count,
          SearchConfirmStatus: "N", // 미확인 알림만
          LoginUserId: localStorage.getItem("userid"),
          SearchUserId: "All",
        };

        const response = await apiClient.post(
          "/api/Alarm/all-alarm-list",
          searchParam
        );

        if (response.status === 200) {
          const data = JSON.parse(response.data);

          // 새로운 알림 추가
          const additionalNotifications = Array.isArray(data.data)
            ? data.data
            : [];
          this.notifications.push(...additionalNotifications);

          // unreadCount 동기화
          this.unreadCount = data.meta.totalCount || this.notifications.length;
        }
      } catch (error) {
        console.error("추가 알림 가져오는 중 오류 발생:", error);
      }
    },

    handleOutsideClick(event) {
      const notificationDropdown = this.$refs.notificationDropdown;
      const notificationContainer = this.$refs.notificationContainer;

      if (
        notificationDropdown &&
        !notificationDropdown.contains(event.target) &&
        notificationContainer &&
        !notificationContainer.contains(event.target)
      ) {
        this.closeNotification(); // 알림 드롭다운 닫기
      }
    },
    toggleNotification() {
      this.showNotifications = !this.showNotifications;
      if (this.showNotifications) {
        this.fetchNotifications(); // 알림 데이터를 가져옵니다.
      }
    },
    closeNotification() {
      this.showNotifications = false; // 드롭다운 닫기
    },
    async fetchNotifications() {
      console.log("MainHeader----1-----");
      try {
        // 기존 fetchData 메서드 호출
        const searchParam = {
          Page: 1,
          PageSize: 5, // 알림 목록에서 가져올 항목 수 제한
          SearchConfirmStatus: "N", // 미확인 알림만
          SearchStatus: "A", // 전체 카테고리
          SearchBoardStatus: "", // 게시판 전체
          SearchEmployeeStatus: "A", // 모든 직원
          LoginUserId: localStorage.getItem("userid"),
          SearchUserId: "All",
        };
        console.log("MainHeader----2-----");
        const response = await apiClient.post(
          "/api/Alarm/all-alarm-list",
          searchParam
        );

        if (response.status === 200) {
          console.log("MainHeader----3-----");
          const data = JSON.parse(response.data);

          this.notifications = Array.isArray(data.data) ? data.data : [];
          this.unreadCount = data.meta.totalCount || 0; // 알림 개수 갱신
          console.log("Fetched notifications:", this.notifications);
        }
      } catch (error) {
        console.log("MainHeader----4-----");
        console.error("Error fetching alarm notifications:", error);
      }
    },
    async markAsRead(notificationId) {
      try {
        await this.$axios.post(
          `/api/notifications/${notificationId}/mark-as-read`
        );
        this.notifications = this.notifications.map((notification) =>
          notification.id === notificationId
            ? { ...notification, read: true }
            : notification
        );
        this.unreadCount -= 1;
      } catch (error) {
        console.error(
          "알림을 읽음으로 표시하는 중 오류가 발생했습니다:",
          error
        );
      }
    },
    handleLogoClick() {
      const randomString = new Date().getTime();

      this.$router.replace({
        path: "/notices/list",
        query: { reload: randomString },
      });
      // if (this.userType === "C") {
      //   this.$router.push("/projects/companylist"); // 'C' 사용자일 경우 회사 프로젝트 리스트로 이동

      //   const randomString = new Date().getTime();

      //   this.$router.replace({
      //     path: "/projects/companylist",
      //     query: { reload: randomString },
      //   });
      // } else if (this.userType === "M") {
      //   this.$router.push("/projects/list"); // 'M' 사용자일 경우 개인 프로젝트 리스트로 이동

      //   const randomString = new Date().getTime();

      //   this.$router.replace({
      //     path: "/projects/list",
      //     query: { reload: randomString },
      //   });
      // }
    },
    refreshManagers(num) {
      if (this.$route.path === "/notices/list" && num === 7) {
        // 동일한 경로로 클릭했을 때는 그냥 페이지를 새로고침합니다.
        const randomString = new Date().getTime();

        this.$router.replace({
          path: "/notices/list",
          query: { reload: randomString },
        });
      }
      if (this.$route.path === "/notices/list" && num === 6) {
        // 동일한 경로로 클릭했을 때는 그냥 페이지를 새로고침합니다.
        const randomString = new Date().getTime();

        this.$router.replace({
          path: "/notices/list",
          query: { reload: randomString },
        });
      }
      if (this.$route.path === "/managers/list" && num === 5) {
        // 동일한 경로로 클릭했을 때는 그냥 페이지를 새로고침합니다.
        const randomString = new Date().getTime();

        this.$router.replace({
          path: "/managers/list",
          query: { reload: randomString },
        });
      }
      if (this.$route.path === "/projects/list" && num === 1) {
        // 동일한 경로로 클릭했을 때는 그냥 페이지를 새로고침합니다.
        const randomString2 = new Date().getTime();

        this.$router.replace({
          path: "/projects/list",
          query: { reload: randomString2 },
        });
      }
      if (this.$route.path === "/accounting/list" && num === 4) {
        // 동일한 경로로 클릭했을 때는 그냥 페이지를 새로고침합니다.
        const randomString3 = new Date().getTime();

        this.$router.replace({
          path: "/accounting/list",
          query: { reload: randomString3 },
        });
      }
      if (this.$route.path === "/orders/list" && num === 3) {
        // 동일한 경로로 클릭했을 때는 그냥 페이지를 새로고침합니다.
        const randomString4 = new Date().getTime();

        this.$router.replace({
          path: "/orders/list",
          query: { reload: randomString4 },
        });
      }
      if (this.$route.path === "/projects/companylist" && num === 2) {
        // 동일한 경로로 클릭했을 때는 그냥 페이지를 새로고침합니다.
        const randomString5 = new Date().getTime();

        this.$router.replace({
          path: "/projects/companylist",
          query: { reload: randomString5 },
        });
      }
    },
    checkAuth() {
      const token_userid = localStorage.getItem("userid");

      if (!token_userid) {
        this.$router.push({ path: "/" });
      } else if (this.$route.path === "/") {
        this.$router.push({ path: "/myinfo/view" });
      }
    },
    logout() {
      if (confirm("로그아웃 하시겠습니까?")) {
        this.toast.success("로그아웃 되었습니다.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        localStorage.clear();
        this.$store.dispatch("updateUserName", ""); // Vuex 스토어에서 사용자 이름 제거
        this.$store.dispatch("updateUserType", ""); // Vuex 스토어에서 사용자 이름 제거
        this.$router.push({ path: "/" });
      }
    },

    selectMenu(menu) {
      // 선택된 메뉴에 따라 이벤트 발생
      if (menu === "정산관리") {
        this.showComingSoonMessage();
      } else {
        // 선택된 메뉴에 따라 이벤트 발생
        this.$emit("menu-selected", menu);
      }
    },
    isActive(path) {
      return this.$route.path.startsWith(path);
    },
  },
};
</script>

<style scoped>
/* 필요한 스타일 정의 */
.logo img {
  cursor: pointer; /* 클릭 가능한 커서 모양 추가 */
  filter: none; /* 불필요한 필터 제거 */
  opacity: 1; /* 투명도 유지 */
}
.notification-container {
  position: relative;
  display: inline-block;
  margin-left: 15px;
  cursor: pointer;
}
.notification-container img {
  width: 24px;
  height: 24px;
}
.notification-badge {
  position: absolute;
  top: -5px;
  right: -10px;
  background-color: red;
  color: white;
  border-radius: 50%;
  font-size: 12px;
  padding: 2px 6px;
}
.notification-dropdown {
  position: absolute;
  right: 0;
  top: 50px;
  width: 300px;
  background-color: #ffffff; /* 배경색 */
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  color: #333; /* 텍스트 색상 */
}
.notification-dropdown ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.notification-dropdown li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #f0f0f0;
  cursor: pointer;
  background-color: #ffffff; /* 기본 배경색 */
  color: #333; /* 기본 텍스트 색상 */
}

.notification-dropdown li:hover {
  background-color: #f9f9f9; /* hover 시 배경색 */
  color: #000; /* hover 시 텍스트 색상 */
}

.notification-item {
  flex-grow: 1; /* 내용 영역 확장 */
}
.notification-image img {
  width: 40px;
  height: 40px;
  border-radius: 50%; /* 원형 이미지 */
  object-fit: cover; /* 이미지 크기 맞춤 */
}

.notification-content {
  flex-grow: 1;
}

.remove-btn {
  background-color: transparent;
  border: none;
  color: #ff0000;
  font-size: 16px;
  cursor: pointer;
}

.remove-btn:hover {
  color: #cc0000;
}

.notification-user {
  font-weight: bold; /* 사용자 이름 강조 */
  margin-right: 5px;
}

.view-all {
  display: block;
  text-align: center;
  padding: 10px;
  background: #0692e7;
  color: white;
  text-decoration: none;
}
.view-all:hover {
  background: #0692e7;
}
.no-notifications {
  padding: 20px;
  text-align: center;
  color: #555;
  font-size: 14px;
}

.disabled-notification {
  background-color: #f0f0f0; /* 회색 배경 */
  color: #a0a0a0; /* 희미한 텍스트 색상 */
  pointer-events: none; /* 클릭 비활성화 */
  cursor: not-allowed; /* 클릭 금지 커서 */
}

.notification-content .notification-user {
  font-weight: bold;
  color: inherit; /* 부모 색상 상속 */
}
</style>
