<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">프로젝트 등록 (마이즈)</div>
        <div class="description">
          프로젝트건 (마이즈) 을 등록합니다.
          <span class="text_red">(* 필수항목)</span>
        </div>

        <article class="right_body">
          <div class="tbl_search tbl_info">
            <div class="part_title">프로젝트등록 유형</div>
            <table>
              <colgroup>
                <col style="width: 18%" class="col_width26" />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>프로젝트유형<span class="text_red">*</span></th>
                  <td>
                    <div class="inner_td">
                      <!-- 직접설치 - D , 사전답사 - P , 유지보수 -M -->
                      <button
                        type="button"
                        :class="{
                          on: projectType === 'D',
                        }"
                        @click="selectProjectType('D')"
                      >
                        설치공사(직접)
                      </button>
                      <button
                        type="button"
                        :class="{
                          on: projectType === 'P',
                        }"
                        @click="selectProjectType('P')"
                      >
                        사전답사
                      </button>
                      <button
                        type="button"
                        :class="{
                          on: projectType === 'M',
                        }"
                        @click="selectProjectType('M')"
                      >
                        유지보수
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-if="projectType === 'M'" class="tbl_search tbl_info">
            <div class="part_title">유지보수정보</div>
            <table>
              <colgroup>
                <col style="width: 18%" class="col_width26" />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>유형<span class="text_red">*</span></th>
                  <td>
                    <div class="inner_td">
                      <!-- 원격 - R , 방문 - V  -->
                      <button
                        type="button"
                        :class="{
                          on: maintenanceType === 'R',
                        }"
                        @click="selectMaintenanceType('R')"
                      >
                        원격
                      </button>
                      <button
                        type="button"
                        :class="{
                          on: maintenanceType === 'V',
                        }"
                        @click="selectMaintenanceType('V')"
                      >
                        방문
                      </button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>결제형식<span class="text_red">*</span></th>
                  <td>
                    <div class="inner_td">
                      <!-- 유상 - O , 무상 - X  -->
                      <button
                        type="button"
                        :class="{
                          on: payType === 'O',
                        }"
                        @click="selectPayType('O')"
                      >
                        유상
                      </button>
                      <button
                        type="button"
                        :class="{
                          on: payType === 'X',
                        }"
                        @click="selectPayType('X')"
                      >
                        무상
                      </button>
                    </div>
                  </td>
                </tr>
                <tr v-if="payType === 'O'">
                  <th>결제비용<span class="text_red">*</span></th>
                  <td>
                    <input
                      type="text"
                      class="w_250 amount-input"
                      placeholder="결제 비용을 입력해주세요"
                      v-model="formattedRealAmount"
                      ref="formattedRealAmount"
                      @input="formatRealAmount"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="tbl_search tbl_info">
            <div class="part_title">프로젝트정보</div>
            <table>
              <colgroup>
                <col style="width: 18%" class="col_width26" />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>프로젝트명<span class="text_red">*</span></th>
                  <td>
                    <input
                      type="text"
                      class="w_250"
                      placeholder="프로젝트명을 입력해주세요"
                      v-model="projectName"
                      ref="projectName"
                    />
                  </td>
                </tr>
                <tr>
                  <th>지역<span class="text_red">*</span></th>
                  <td>
                    <div class="inner_td">
                      <select
                        class="w_250"
                        v-model="selectedArea"
                        @change="handleAreaChange"
                      >
                        <option value="0">전체보기</option>
                        <option
                          v-for="area in areas"
                          :key="area.id"
                          :value="area.id"
                        >
                          {{ area.areaName }}
                        </option>
                      </select>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>날짜<span class="text_red">*</span></th>
                  <td>
                    <div class="inner_td">
                      <input
                        type="date"
                        class="w_250"
                        v-model="selectedStartDate"
                        @focus="resetStartDate"
                        @change="addStartDate"
                      />
                    </div>
                    <!-- 선택된 프로젝트 시작일 목록 -->
                    <div
                      v-for="(date, index) in selectedStartDates"
                      :key="index"
                      class="material-item"
                    >
                      <span>{{ date }} ({{ getDayOfWeek(date) }})</span>
                      <button type="button" @click="removeStartDate(index)">
                        삭제
                      </button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>시간<span class="text_red">*</span></th>
                  <td>
                    <div class="inner_td">
                      <flat-pickr
                        v-model="selectedTime"
                        class="w_250"
                        :config="timePickerConfig"
                        placeholder="시간을 선택하세요"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>{{ projectDetailLabel }}</th>
                  <td>
                    <div class="inner_td">
                      <textarea
                        placeholder="상세설명을 입력하세요. "
                        v-model="detailDesc"
                        ref="detailDesc"
                      ></textarea>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>투입자재목록<span class="text_red">*</span></th>
                  <td>
                    <button
                      type="button"
                      :class="{ on: !showMaterialDropdown }"
                      @click="toggleMaterialDropdown"
                      style="margin-bottom: 5px"
                    >
                      투입자재 없음
                    </button>
                    <div class="inner_td" v-if="showMaterialDropdown">
                      <!-- 자재 선택 dropdown -->
                      <multiselect
                        v-model="selectedMaterial"
                        :options="materials"
                        :custom-label="customMaterialLabel"
                        track-by="id"
                        placeholder="자재를 검색하세요"
                        @select="addMaterial"
                        :searchable="true"
                        :close-on-select="true"
                        :allow-empty="false"
                      />
                    </div>
                    <!-- 선택된 자재 목록 -->
                    <div
                      v-for="(material, index) in selectedMaterials"
                      :key="index"
                      class="material-item"
                    >
                      <span>{{
                        material.materialsName +
                        (material.modelName
                          ? " (" + material.modelName + ")"
                          : "")
                      }}</span>
                      <button
                        type="button"
                        @click="decreaseQuantityByTen(index)"
                      >
                        -10
                      </button>
                      <button type="button" @click="decreaseQuantity(index)">
                        -
                      </button>
                      <span>{{ material.quantity }}</span>
                      <button type="button" @click="increaseQuantity(index)">
                        +
                      </button>
                      <button
                        type="button"
                        @click="increaseQuantityByTen(index)"
                      >
                        +10
                      </button>
                      <button type="button" @click="removeMaterial(index)">
                        삭제
                      </button>
                    </div>
                  </td>
                </tr>
                <tr v-if="projectType === 'D'">
                  <th>담당자 (시공)</th>
                  <td>
                    <div class="inner_td">
                      <textarea
                        placeholder="시공을 진행하는 인원이 있다면 입력하세요. "
                        v-model="constructionManager"
                        ref="constructionManager"
                      ></textarea>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>담당자 (영업)<span class="text_red">*</span></th>
                  <td>
                    <div class="inner_td">
                      <button
                        v-for="salesmanager in salesManagers"
                        :key="salesmanager.userId"
                        type="button"
                        :class="{
                          on: selectedSalesManager === salesmanager.userId,
                        }"
                        @click="selectSalesManager(salesmanager.userId)"
                      >
                        {{ salesmanager.adminName }}
                      </button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>담당자 (PM)<span class="text_red">*</span></th>
                  <td>
                    <div class="inner_td">
                      <button
                        v-for="pmmanager in pmManagers"
                        :key="pmmanager.userId"
                        type="button"
                        :class="{
                          on: selectedPmManagers.includes(pmmanager.userId),
                        }"
                        @click="selectPmManager(pmmanager.userId)"
                      >
                        {{ pmmanager.adminName }}
                      </button>
                      <span class="text_red">( * 여러인원 선택 가능 )</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>특이사항</th>
                  <td>
                    <div class="inner_td">
                      <textarea
                        placeholder="내용을 입력하세요. "
                        v-model="addMemo"
                        ref="addMemo"
                      ></textarea>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="tbl_search tbl_info">
            <div class="part_title">고객사 정보 (+ 현장 정보)</div>
            <table>
              <colgroup>
                <col style="width: 18%" />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>고객사 상호<span class="text_red">*</span></th>
                  <td>
                    <input
                      type="text"
                      class="w_250"
                      placeholder="고객사 상호명을 입력해주세요"
                      v-model="customerName"
                      ref="customerName"
                    />
                  </td>
                </tr>
                <tr>
                  <th>상세주소<span class="text_red">*</span></th>
                  <td class="td_address">
                    <div>
                      <input
                        type="text"
                        class="w_150"
                        placeholder="우편번호"
                        v-model="postalCode"
                        ref="postalCode"
                        readonly
                      />
                      <button
                        type="button"
                        class="keep"
                        @click="openPostcodePopup"
                      >
                        주소찾기
                      </button>
                    </div>
                    <input
                      type="text"
                      class="w_250"
                      placeholder="주소"
                      v-model="address"
                      ref="address"
                    />
                    <input
                      type="text"
                      class="w_250"
                      placeholder="상세주소"
                      v-model="detailedAddress"
                      ref="detailedAddress"
                    />
                  </td>
                </tr>
                <tr>
                  <th>현장명<span class="text_red">*</span></th>
                  <td>
                    <input
                      type="text"
                      class="w_250"
                      v-model="placeName"
                      ref="placeName"
                      placeholder="현장명을 입력해주세요"
                    />
                  </td>
                </tr>
                <tr>
                  <th>현장 담당자 이름<span class="text_red">*</span></th>
                  <td>
                    <input
                      type="text"
                      class="w_250"
                      v-model="placemanagerName"
                      ref="placemanagerName"
                      placeholder="현장 담당자 이름을 입력해주세요"
                    />
                  </td>
                </tr>
                <tr>
                  <th>현장 담당자 연락처<span class="text_red">*</span></th>
                  <td>
                    <input
                      type="text"
                      class="w_250"
                      v-model="placemanagerMobile"
                      ref="placemanagerMobile"
                      @input="formatPhoneNumber"
                      placeholder="현장 담당자 연락처를 입력해주세요"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="btn_bottom_a">
            <button type="button" class="on" @click="saveConstruction">
              <!-- saveConstruction  -->
              등록
            </button>
            <button type="button" @click="goToConstructionList">취소</button>
          </div>
        </article>
      </div>
      <!-- 삼성인터넷브라우저에서 안되서 원인 파악하다가 스타일문제로 확인! 임시방편임 -->
      <div style="margin-top: 100px"></div>
    </div>
  </div>
</template>

<script>
/* global daum */
import apiClient from "@/apiClient"; // 설정 파일에서 가져온 axios 인스턴스
import { useToast } from "vue-toastification";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import FlatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import CryptoJS from "crypto-js";

export default {
  name: "ProjectMizeAdd",
  components: {
    Multiselect,
    FlatPickr,
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      decryptedData: null,

      copiedData: {},
      selectedTime: "", // 선택된 시간을 저장
      timePickerConfig: {
        enableTime: true,
        noCalendar: true, // 시간만 선택할 수 있게 설정
        dateFormat: "H:i", // 시간 형식 지정 (24시간 형식)
        time_24hr: true, // 24시간 형식 사용
      },
      projectType: "D",
      maintenanceType: "",
      payType: "",
      showMaterialDropdown: true, // 자재 선택 dropdown을 표시할지 여부
      selectedArea: "0", // 전체보기 기본 값 설정
      areas: [], // API에서 가져온 지역 목록을 저장할 배열
      materials: [], // 자재 목록을 저장할 배열
      selectedMaterial: null, // 선택된 자재를 저장할 변수
      selectedMaterials: [], // 선택된 자재 목록을 저장할 배열
      selectedStartDate: "", // 선택된 프로젝트 시작일
      isDateInitialized: false, // 플래그로 처음 클릭을 구분
      selectedStartDates: [], // 선택된 프로젝트 시작일 목록
      postalCode: "", // 우편번호 입력값
      address: "", // 주소 입력값
      detailedAddress: "", // 상세주소 입력값
      salesManagers: [], // 영업 담당자 목록을 저장할 배열
      selectedSalesManager: "", // 선택된 영업 담당자
      pmManagers: [], // PM 담당자 목록을 저장할 배열
      selectedPmManager: "", // 선택된 PM 담당자
      checkdatedevice: 0,
      placemanagerMobile: "",
      placeName: "",
      placemanagerName: "",
      formattedRealAmount: "", // ','가 포함된 금액을 저장하는 변수
      selectedPmManagers: [],
    };
  },

  created() {
    // 라우터로 전달된 데이터를 JSON으로 파싱
    // if (this.$route.query.data) {
    //   try {
    //     this.copiedData = JSON.parse(this.$route.query.data);
    //     this.initializeFieldsFromCopiedData();
    //   } catch (error) {
    //     console.error("Error parsing copied data:", error);
    //   }
    // }
    if (this.$route.query.data) {
      try {
        this.copiedData = this.decryptData(this.$route.query.data);
        this.initializeFieldsFromCopiedData();
        console.log("Decrypted Data:", this.copiedData); // 복호화된 데이터 확인
      } catch (error) {
        console.error("Error decrypting data:", error);
      }
    }
  },
  computed: {
    userId() {
      return this.$store.getters.getUserid;
    },
    userName() {
      return this.$store.getters.getUsername;
    },
    userType() {
      return this.$store.getters.getUsertype;
    },
    userPermission() {
      return this.$store.getters.getUserpermission;
    },
    projectDetailLabel() {
      switch (this.projectType) {
        case "D":
          return "설치공사 상세내용";
        case "P":
          return "영업 상세내용";
        case "M":
          return "문의내용 상세내용";
        default:
          return "프로젝트 상세내용";
      }
    },

    areaName() {
      // 지역 ID를 기반으로 실제 표시할 이름 반환
      return (
        this.areas.find((area) => area.id === this.selectedArea)?.areaName ||
        "Unknown Area"
      );
    },
    mizeSalesUserName() {
      // 영업 담당자 ID를 기반으로 실제 표시할 이름 반환
      return (
        this.salesManagers.find(
          (manager) => manager.userId === this.selectedSalesManager
        )?.adminName || "Unknown Sales Manager"
      );
    },
  },
  methods: {
    decryptData(cipherText) {
      const secretKey = process.env.VUE_APP_SECRET_KEY; // 보안 키
      const bytes = CryptoJS.AES.decrypt(cipherText, secretKey);
      return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    },
    initializeFieldsFromCopiedData() {
      // 복사된 데이터를 필드에 매핑
      this.projectName = this.copiedData.projectName || "";
      this.selectedArea = this.copiedData.selectedArea || "0";
      this.postalCode = this.copiedData.postalCode || "";
      this.address = this.copiedData.address || "";
      this.detailedAddress = this.copiedData.detailedAddress || "";
      this.detailDesc = this.copiedData.detailDesc || "";
      this.selectedSalesManager = this.copiedData.selectedSalesManager || "";

      // console.log("3----" + this.copiedData.selectedPmManager);
      // this.selectedPmManager = this.selectPmManager(
      //   this.copiedData.selectedPmManager
      // );

      this.customerName = this.copiedData.customerName || "";
      this.placemanagerName = this.copiedData.placemanagerName || "";
      this.placemanagerMobile = this.copiedData.placemanagerMobile || "";
      this.placeName = this.copiedData.placeName || "";

      // PM Manager 처리
      if (this.copiedData.selectedPmManager) {
        // '^'로 구분된 PM ID 문자열을 배열로 변환
        const pmManagerIds = this.copiedData.selectedPmManager.split("^");
        this.selectedPmManagers = pmManagerIds; // 배열에 저장

        // 필요 시 추가 처리 (선택된 ID를 이용한 로직)
        pmManagerIds.forEach((pmManagerId) => {
          console(pmManagerId);
          this.selectPmManager(pmManagerId); // ID를 선택 처리
        });
      } else {
        this.selectedPmManagers = [];
      }
    },
    selectPmManager(pmmanagerId) {
      if (pmmanagerId === "userid") {
        // '미지정'이 선택되면 다른 PM 선택을 모두 해제
        this.selectedPmManagers = ["userid"];
      } else {
        const index = this.selectedPmManagers.indexOf(pmmanagerId);

        // '미지정'이 선택되어 있다면 해제
        const noneIndex = this.selectedPmManagers.indexOf("userid");
        if (noneIndex > -1) {
          this.selectedPmManagers.splice(noneIndex, 1);
        }

        if (index > -1) {
          // 이미 선택된 경우 해제
          this.selectedPmManagers.splice(index, 1);
        } else {
          // 선택된 PM 추가
          this.selectedPmManagers.push(pmmanagerId);
        }
      }
    },
    selectPayType(type) {
      this.payType = type;
    },
    selectMaintenanceType(type) {
      this.maintenanceType = type;
    },
    selectProjectType(type) {
      this.projectType = type; // 선택된 프로젝트 유형 업데이트
    },
    toggleMaterialDropdown() {
      this.showMaterialDropdown = !this.showMaterialDropdown; // 자재 선택 dropdown 보임/숨김 상태 전환
    },
    // 전화번호 포맷팅 함수
    formatPhoneNumber(event) {
      let input = event.target.value.replace(/\D/g, ""); // 숫자 이외의 문자 제거

      // 변환된 값을 담을 변수 선언
      let formattedNumber = "";

      if (input.length <= 3) {
        formattedNumber = input;
      } else if (input.length <= 7) {
        formattedNumber = input.slice(0, 3) + "-" + input.slice(3);
      } else {
        formattedNumber =
          input.slice(0, 3) +
          "-" +
          input.slice(3, 7) +
          "-" +
          input.slice(7, 11);
      }

      // 포맷팅된 값을 다시 반영
      this.placemanagerMobile = formattedNumber;
    },
    getFormattedDate() {
      const date = new Date();
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");

      return `${year}-${month}-${day}`;
    },
    formatRealAmount(event) {
      // 입력된 값에서 숫자만 추출
      const rawValue = event.target.value.replace(/[^0-9]/g, "");

      // 숫자를 실제 금액 변수에 저장
      this.realAmount = parseInt(rawValue, 10) || 0;

      // 천 단위로 ',' 추가
      this.formattedRealAmount = this.realAmount
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    resetStartDate() {
      // 날짜가 처음 클릭되었을 때만 초기화
      if (!this.isDateInitialized) {
        this.selectedStartDate = null; // 날짜 초기화
        this.isDateInitialized = true; // 이후로는 초기화하지 않도록 설정
      }
    },
    customMaterialLabel(option) {
      return `${option.materialsName} ${
        option.modelName ? `(${option.modelName})` : ""
      }`;
    },
    // selectPmManager(pmmanagerId) {
    //   this.selectedPmManager = pmmanagerId;
    // },
    async fetchPmManagers() {
      try {
        const searchParam = {
          PageSize: 100,
          Page: "1",
        };

        const response = await apiClient.post(
          "/api/User/pm-user-list",
          searchParam
        ); // 예시 API 엔드포인트
        if (response.status === 200) {
          const data = JSON.parse(response.data);
          this.pmManagers = Array.isArray(data.data) ? data.data : [];
          console.log(this.pmManagers);
        }
      } catch (error) {
        console.error("Error fetching pm managers:", error);
      }
    },
    selectSalesManager(salesmanagerId) {
      this.selectedSalesManager = salesmanagerId;
    },
    async fetchSalesManagers() {
      try {
        const searchParam = {
          PageSize: 100,
          Page: "1",
        };

        const response = await apiClient.post(
          "/api/User/sales-user-list",
          searchParam
        ); // 예시 API 엔드포인트
        if (response.status === 200) {
          const data = JSON.parse(response.data);
          this.salesManagers = Array.isArray(data.data) ? data.data : [];
          console.log(this.salesManagers);
        }
      } catch (error) {
        console.error("Error fetching sales managers:", error);
      }
    },
    getDayOfWeek(dateString) {
      //console.log("Received dateString:", dateString);
      const date = new Date(dateString);
      if (isNaN(date.getTime())) {
        console.log("Invalid date:", dateString);
        return "유효하지 않은 날짜";
      }
      const daysOfWeek = ["일", "월", "화", "수", "목", "금", "토"];
      const day = date.getDay();
      return daysOfWeek[day];
    },

    // 프로젝트 시작일 추가
    addStartDate(event) {
      this.checkdatedevice++;
      const dateValue = event.target.value;
      const today = this.getFormattedDate();

      if (this.selectedStartDates.length >= 1) {
        this.toast.error("날짜는 한 개만 선택 가능합니다.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 1.5초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });

        this.selectedStartDate = "";
        return; // 날짜 추가 중지
      }

      if (dateValue >= today) {
        if (this.selectedStartDate) {
          // 이미 선택된 날짜가 있는지 확인
          const exists = this.selectedStartDates.includes(
            this.selectedStartDate
          );

          if (!exists) {
            // 선택된 날짜가 없으면 추가
            this.selectedStartDates.push(this.selectedStartDate);
            // 추가 후 날짜를 빠른 순으로 정렬
            this.selectedStartDates.sort((a, b) => new Date(a) - new Date(b));
          } else {
            this.toast.error("이미 선택된 프로젝트 시작일입니다.", {
              position: "top-center", // 메시지 위치
              timeout: 1500, // 3초 동안 표시
              closeOnClick: true,
              pauseOnHover: true,
              hideProgressBar: true, // 진행 표시줄 숨기기
              closeButton: false, // X 버튼 숨기기
            });
          }

          // 선택 후 초기화
          this.selectedStartDate = "";
        }
      } else {
        // 경영지원팀 혹시 모르니 과거 날짜도 등록 할 수 있도록 처리
        if (this.$store.getters.getUserpermission === "M") {
          if (this.selectedStartDate) {
            // 이미 선택된 날짜가 있는지 확인
            const exists = this.selectedStartDates.includes(
              this.selectedStartDate
            );

            if (!exists) {
              // 선택된 날짜가 없으면 추가
              this.selectedStartDates.push(this.selectedStartDate);
              // 추가 후 날짜를 빠른 순으로 정렬
              this.selectedStartDates.sort((a, b) => new Date(a) - new Date(b));
            } else {
              this.toast.error("이미 선택된 프로젝트 시작일입니다.", {
                position: "top-center", // 메시지 위치
                timeout: 1500, // 3초 동안 표시
                closeOnClick: true,
                pauseOnHover: true,
                hideProgressBar: true, // 진행 표시줄 숨기기
                closeButton: false, // X 버튼 숨기기
              });
            }

            // 선택 후 초기화
            this.selectedStartDate = "";
          }
        } else {
          console.log(this.checkdatedevice);
          this.toast.error("프로젝트 등록 시 과거 날짜는 선택할 수 없습니다.", {
            position: "top-center", // 메시지 위치
            timeout: 1500, // 3초 동안 표시
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: true, // 진행 표시줄 숨기기
            closeButton: false, // X 버튼 숨기기
          });
          // 선택 후 초기화
          this.selectedStartDate = "";
        }
      }
    },
    // 프로젝트 시작일 제거
    removeStartDate(index) {
      this.selectedStartDates.splice(index, 1);
    },
    async fetchMaterials() {
      try {
        const searchParam = {
          PageSize: 100,
          Page: "1",
        };

        const response = await apiClient.post(
          "/api/Materials/all-materials-list",
          searchParam
        );
        if (response.status === 200) {
          const data = JSON.parse(response.data);
          this.materials = Array.isArray(data.data) ? data.data : [];
        }
      } catch (error) {
        console.error("Error fetching materials:", error);
      }
    },
    addMaterial() {
      if (this.selectedMaterial) {
        const exists = this.selectedMaterials.find(
          (mat) => mat.id === this.selectedMaterial.id
        );
        if (!exists) {
          this.selectedMaterials.push({
            ...this.selectedMaterial,
            quantity: 1, // 기본 수량 1로 설정
          });
          this.selectedMaterial = null; // 선택 후 초기화
          console.log("Selected Materials:", this.selectedMaterials);
        } else {
          this.toast.error("이미 선택된 자재입니다.", {
            position: "top-center", // 메시지 위치
            timeout: 1500, // 3초 동안 표시
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: true, // 진행 표시줄 숨기기
            closeButton: false, // X 버튼 숨기기
          });
        }
      }
    },
    increaseQuantity(index) {
      this.selectedMaterials[index].quantity++;
    },
    // 새로운 수량 10 증가 메소드
    increaseQuantityByTen(index) {
      this.selectedMaterials[index].quantity += 10;
    },
    decreaseQuantityByTen(index) {
      if (this.selectedMaterials[index].quantity > 10) {
        this.selectedMaterials[index].quantity -= 10;
      } else {
        this.toast.error("현재 10 이하의 수량으로 더 이상 줄일 수 없습니다.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
      }
    },
    decreaseQuantity(index) {
      if (this.selectedMaterials[index].quantity > 1) {
        this.selectedMaterials[index].quantity--;
      } else {
        this.toast.error("1개 미만 수량으로 선택할 수 없습니다.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
      }
    },
    removeMaterial(index) {
      this.selectedMaterials.splice(index, 1);
    },
    handleAreaChange() {
      this.currentPage = 1; // 지역 변경 시 페이지를 1로 리셋
      //this.fetchData(); // 지역 선택 시 데이터 갱신
    },
    goToConstructionList() {
      this.$router.push({ path: "/projects/mizelist" });
    },
    async fetchAreas() {
      try {
        const searchParam = {
          PageSize: 100,
          Page: "1",
        };
        const response = await apiClient.post(
          "/api/Area/all-area-list",
          searchParam
        );
        if (response.status === 200) {
          const data = JSON.parse(response.data);
          this.areas = Array.isArray(data.data) ? data.data : [];
          console.log(this.areas);
        }
      } catch (error) {
        console.error("Error fetching areas:", error);
      }
    },
    async saveConstruction() {
      if (this.checkFields()) {
        // 프로젝트 시작일을 '^'로 구분된 문자열로 변환
        const formattedStartDates = this.selectedStartDates.join("^");

        console.log("★--ProjectName--" + this.projectName);
        console.log("★--StartDate--" + formattedStartDates);
        console.log("★--AreaId--" + this.selectedArea);
        console.log("★--ZipCode--" + this.postalCode);
        console.log("★--Addr1--" + this.address);
        console.log("★--Addr2--" + this.detailedAddress);
        console.log("★--DetailContent--" + this.detailDesc);
        console.log(
          "★--Materials--" + JSON.stringify(this.selectedMaterials, null, 2)
        );
        console.log("★--EtcMemo--" + this.addMemo);
        console.log("★--MizeSalesUserId--" + this.selectedSalesManager);
        console.log("★--MizePMUserId--" + this.selectedPmManager);
        console.log("★--LoginId--" + localStorage.getItem("userid"));

        const pmManagerIds = this.selectedPmManagers.join("^");
        const pmManagerNames = this.pmManagers
          .filter((pm) => this.selectedPmManagers.includes(pm.userId))
          .map((pm) => pm.adminName)
          .join("^");

        try {
          const response = await apiClient.post(
            "/api/MizeConstruction/construction-add",
            {
              ProjectName: this.projectName,
              StartDate: formattedStartDates,
              StartTime: this.selectedTime,
              AreaId: this.selectedArea,
              AreaName: this.areaName,
              ZipCode: this.postalCode,
              Addr1: this.address,
              Addr2: this.detailedAddress,
              DetailContent: this.detailDesc,
              MizeMaterials: this.selectedMaterials.map((mat) => ({
                MaterialId: mat.id,
                MaterialCnt: mat.quantity,
                MaterialName: mat.materialsName,
              })),
              EtcMemo: this.addMemo,
              MizeSalesUserId: this.selectedSalesManager,
              MizeSalesUserName: this.mizeSalesUserName,
              //   MizePMUserId: this.selectedPmManager,
              MizePMUserId: pmManagerIds, // PM 담당자 ID '^'로 구분
              MizePMUserName: pmManagerNames, // PM 담당자 이름 '^'로 구분
              MizeConstructionUserMemo: this.constructionManager,
              CustomerName: this.customerName,
              LoginId: localStorage.getItem("userid"),
              // 필요한 다른 필드 추가
              PlaceManagerMobile: this.placemanagerMobile,
              PlaceManagerName: this.placemanagerName,
              PlaceName: this.placeName,
              MaterialsNoYN: this.showMaterialDropdown == true ? "N" : "Y",
              ProjectType: this.projectType,
              MaintenanceType: this.maintenanceType,
              PayType: this.payType,
              Price: this.formattedRealAmount,
            }
          );
          if (response.status === 200) {
            this.toast.success("프로젝트 정보가 성공적으로 등록되었습니다.", {
              position: "top-center", // 메시지 위치
              timeout: 1500, // 3초 동안 표시
              closeOnClick: true,
              pauseOnHover: true,
              hideProgressBar: true, // 진행 표시줄 숨기기
              closeButton: false, // X 버튼 숨기기
            });
            //this.g
            this.goToConstructionList();
          }
        } catch (error) {
          console.error("Error saving construction data:", error);
          this.toast.error("프로젝트 정보가 등록 중 오류가 발생했습니다.", {
            position: "top-center", // 메시지 위치
            timeout: 1500, // 3초 동안 표시
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: true, // 진행 표시줄 숨기기
            closeButton: false, // X 버튼 숨기기
          });
        }
      }
    },
    checkFields() {
      if (!this.projectType) {
        // 프로젝트 유형이 선택되지 않았을 때
        this.toast.error("프로젝트 유형을 선택해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return false;
      }

      if (this.projectType === "M") {
        // 유형, 결제형식, 결제비용 세개 체크
        if (!this.maintenanceType) {
          this.toast.error("유지보수 형식을 선택해주세요.", {
            position: "top-center", // 메시지 위치
            timeout: 1500, // 3초 동안 표시
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: true, // 진행 표시줄 숨기기
            closeButton: false, // X 버튼 숨기기
          });
          return false;
        }

        // 유형, 결제형식, 결제비용 세개 체크
        if (!this.payType) {
          this.toast.error("유지보수 결제 형식을 선택해주세요.", {
            position: "top-center", // 메시지 위치
            timeout: 1500, // 3초 동안 표시
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: true, // 진행 표시줄 숨기기
            closeButton: false, // X 버튼 숨기기
          });
          return false;
        }

        // 결제 형식이 유상일 경우 비용 체크
        if (this.payType === "O") {
          if (!this.formattedRealAmount) {
            this.$refs.formattedRealAmount.focus();
            this.toast.error("결제비용을 입력해주세요.", {
              position: "top-center", // 메시지 위치
              timeout: 1500, // 3초 동안 표시
              closeOnClick: true,
              pauseOnHover: true,
              hideProgressBar: true, // 진행 표시줄 숨기기
              closeButton: false, // X 버튼 숨기기
            });

            return false;
          }
        }
      }

      if (!this.projectName) {
        this.$refs.projectName.focus();
        this.toast.error("프로젝트명을 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });

        return false;
      }
      if (this.selectedArea === "0") {
        // 전체보기 (기본값) 또는 선택되지 않은 상태
        this.toast.error("지역을 선택해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return false;
      }
      if (this.selectedStartDates.length === 0) {
        // 프로젝트 시작일이 선택되지 않았을 때
        this.toast.error("날짜를 선택해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return false;
      }

      if (this.selectedTime.length === 0) {
        // 프로젝트 시작일이 선택되지 않았을 때
        this.toast.error("시간을 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return false;
      }

      if (this.projectType === "D") {
        if (this.showMaterialDropdown && this.selectedMaterials.length === 0) {
          // 투입자재목록이 선택되지 않았을 때
          this.toast.error("투입자재목록을 선택해주세요.", {
            position: "top-center", // 메시지 위치
            timeout: 1500, // 3초 동안 표시
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: true, // 진행 표시줄 숨기기
            closeButton: false, // X 버튼 숨기기
          });
          return false;
        }
      }

      //   if (!this.constructionManager) {
      //     // 담당자(시공) 항목이 선택되지 않았을 때
      //     this.$refs.constructionManager.focus();
      //     this.toast.error("담당자(시공)를 입력해주세요.", {
      //       position: "top-center", // 메시지 위치
      //       timeout: 1500, // 3초 동안 표시
      //       closeOnClick: true,
      //       pauseOnHover: true,
      //       hideProgressBar: true, // 진행 표시줄 숨기기
      //       closeButton: false, // X 버튼 숨기기
      //     });
      //     return false;
      //   }

      if (!this.selectedSalesManager) {
        // 담당자(영업) 항목이 선택되지 않았을 때
        this.toast.error("담당자(영업)를 선택해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return false;
      }

      // selectedPmManager
      if (!this.selectedPmManagers) {
        // 담당자(영업) 항목이 선택되지 않았을 때
        this.toast.error("담당자(PM)를 선택해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return false;
      }
      if (!this.customerName) {
        this.$refs.customerName.focus();
        // 고객사 상호가 입력되지 않았을 때
        this.toast.error("고객사 상호를 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return false;
      }

      if (!this.detailedAddress) {
        this.$refs.detailedAddress.focus();
        // 상세주소가 입력되지 않았을 때
        this.toast.error("상세주소를 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return false;
      }

      if (!this.placeName) {
        this.$refs.placeName.focus();

        this.toast.error("현장명을 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return false;
      }

      if (!this.placemanagerName) {
        this.$refs.placemanagerName.focus();
        // 상세주소가 입력되지 않았을 때
        this.toast.error("현장 담당자 이름을 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return false;
      }

      if (!this.placemanagerMobile) {
        this.$refs.placemanagerMobile.focus();
        // 상세주소가 입력되지 않았을 때
        this.toast.error("현장 담당자 연락처를 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return false;
      }

      return true;
    },
    openPostcodePopup() {
      new daum.Postcode({
        oncomplete: (data) => {
          this.postalCode = data.zonecode;
          this.address = data.address;
          this.$refs.detailedAddress.focus();
        },
      }).open();
    },
  },
  mounted() {
    this.fetchAreas(); // 컴포넌트가 마운트될 때 회사 정보를 가져옵니다.
    this.fetchMaterials(); // 자재 데이터를 가져오기
    this.fetchSalesManagers(); // 영업 담당자 목록을 가져옴
    this.fetchPmManagers(); // 영업 담당자 목록을 가져옴

    console.log("Fetched materials: ", JSON.stringify(this.materials, null, 2));
  },
};
</script>

<style scoped>
/* 기타 스타일 */
.material-item {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.material-item button {
  margin: 0 5px;
  padding: 5px;
}

.material-item span {
  margin-right: 10px;
}
.multiselect__input {
  position: relative;
  z-index: 1;
}

.multiselect__content {
  position: absolute;
  top: calc(100% + 5px); /* 입력 필드 바로 아래로 위치시킴 */
  z-index: 9999;
}
.flatpickr-time {
  width: 120px; /* 시간 선택기의 전체 너비 제한 */
}

.flatpickr-time .flatpickr-time-separator {
  margin: 0 5px; /* 시간과 분 사이 간격 조절 */
}

.flatpickr-calendar {
  background: transparent;
  opacity: 0;
  display: none;
  text-align: center;
  visibility: hidden;
  padding: 0;
  -webkit-animation: none;
  animation: none;
  direction: ltr;
  border: 0;
  font-size: 14px;
  line-height: 24px;
  border-radius: 5px;
  position: absolute;
  /* width: 307.875px; */
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  background: #fff;
  -webkit-box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6,
    0 -1px 0 #e6e6e6, 0 3px 13px rgba(0, 0, 0, 0.08);
  box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6,
    0 -1px 0 #e6e6e6, 0 3px 13px rgba(0, 0, 0, 0.08);
}
</style>
