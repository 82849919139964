<template>
  <div class="comment">
    <div class="comment_wrapper" :id="`comment-${comment.commentId}`">
      <div class="comment_name">
        {{ comment.userName }} {{ " " + comment.userPosition }}
      </div>
      <div class="comment_innerbox">
        <!-- commentContent가 '[자동댓글]'인 경우 -->
        <div
          class="comment_autobox"
          v-if="comment.commentContent === '[자동댓글]'"
        >
          <div class="comment_rowbox">
            <!-- [자동댓글] 이라는 문구 주석 -->
            <!-- <div class="comment_content">{{ comment.commentContent }}</div> -->
            <div>
              <b>{{ comment.itemName }}</b> 변경
            </div>
          </div>
          <div class="comment_auto">
            <!-- 자동 댓글일 때 다른 컬럼 표시 -->
            <div class="comment_contentbox">
              <div class="comment_content_auto">
                <div>
                  <span class="prevvalue">기존 : </span>
                  <!-- <b
                  >{{ comment.originValue.startsWith("#") ? `
                  <div
                    style="background-color:${comment.originValue}; widht:20px; height:20px"
                  ></div>
                  ` : comment.originValue }}</b
                > -->
                  <b>{{ comment.originValue }}</b>
                </div>
                <div>
                  <span class="nextvalue">변경 : </span
                  ><b>{{ comment.newValue }}</b>
                </div>
                <div>변경사유 : {{ comment.changeReason }}</div>
              </div>
              <div class="date">{{ comment.createdDtm }}</div>
            </div>
            <div class="comment_btns">
              <button @click="showReply = !showReply">답글 달기</button>
            </div>
          </div>
        </div>

        <!-- commentContent에 '[자동댓글]'이 포함된 경우 (포함된 경우) -->
        <div
          class="comment_autobox"
          v-else-if="comment.commentContent.includes('[자동댓글]')"
        >
          <div class="comment_contentbox">
            <div class="comment_content_auto">
              {{ comment.commentContent.replace("[자동댓글]", "") }}
            </div>
            <div class="date">{{ comment.createdDtm }}</div>
          </div>
          <div class="comment_btns">
            <button @click="showReply = !showReply">답글 달기</button>
          </div>
        </div>

        <div class="comment_normal" v-else>
          <!-- <div class="comment_content">{{ comment.commentContent }}</div> -->
          <!-- 수정 모드일 때는 텍스트 영역을 표시 -->
          <div v-if="isEditing">
            <textarea
              v-model="editedComment"
              class="comment-textarea"
            ></textarea>
            <div class="comment_btns">
              <button @click="saveEdit">저장</button
              ><button @click="cancelEdit">취소</button>
            </div>
          </div>
          <!-- 수정 모드가 아닐 때는 댓글 내용을 표시 -->
          <div v-else>
            <div class="comment_contentbox">
              <div class="comment_content">
                <!-- {{ comment.commentContent }} -->

                <p v-html="formatContent(comment.commentContent)"></p>
              </div>
              <div class="date">{{ comment.createdDtm }}</div>
            </div>
            <div class="comment_btns">
              <button @click="showReply = !showReply">답글 달기</button>
              <button v-if="isCommentOwner" @click="editComment">수정</button>
              <button v-if="isCommentOwner" @click="deleteComment">삭제</button>
            </div>
          </div>
        </div>
      </div>
      <!-- itemName / changeReason / originValue / newValue / /  -->
      <div class="comment_addbox" v-if="showReply">
        <textarea v-model="replyText"></textarea>
        <button @click="submitReply">답글작성완료</button>
      </div>
    </div>
    <!-- 재귀적으로 대댓글을 렌더링 -->
    <div class="replies" v-if="comment.replies && comment.replies.length">
      <RecursiveComment
        v-for="reply in comment.replies"
        :key="reply.id"
        :comment="reply"
        @submit-reply="handleReply"
        @submit-reply-success="$emit('submit-reply-success')"
      />
    </div>
  </div>
</template>

<script>
import apiClient from "@/apiClient";
import { useToast } from "vue-toastification";

export default {
  name: "RecursiveComment",
  setup() {
    const toast = useToast();
    return { toast };
  },
  props: {
    comment: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showReply: false,
      replyText: "",
      isEditing: false, // 수정 모드 상태
      editedComment: this.comment.commentContent, // 수정된 댓글 내용
    };
  },
  computed: {
    // 현재 로그인한 사용자가 댓글 작성자인지 여부를 확인
    isCommentOwner() {
      const loggedInUserId = localStorage.getItem("userid");
      return loggedInUserId === this.comment.userId;
    },
  },
  watch: {
    // comment가 변경될 때 editedComment를 업데이트
    comment: {
      immediate: true,
      handler(newVal) {
        this.editedComment = newVal.commentContent;
      },
    },
  },
  methods: {
    formatContent(content) {
      // \r\n 또는 \n을 <br> 태그로 변환
      return content.replace(/\r\n/g, "<br>").replace(/\n/g, "<br>");
    },
    // 수정 버튼 클릭 시 수정 모드로 전환
    editComment() {
      this.isEditing = true;
    },
    // 수정 취소
    cancelEdit() {
      this.isEditing = false;
      this.editedComment = this.comment.commentContent;
    }, // 댓글 삭제
    async deleteComment() {
      if (confirm("정말로 이 댓글을 삭제하시겠습니까?")) {
        try {
          const response = await apiClient.post("/api/Comment/comment-delete", {
            CommentId: this.comment.commentId,
            UserId: localStorage.getItem("userid"),
            LoginId: localStorage.getItem("userid"),
          });

          if (response.status === 200) {
            // 삭제 후 부모 컴포넌트에 알리기
            this.$emit("submit-reply-success");
          }
        } catch (error) {
          this.toast.error("댓글을 삭제하는 도중 오류가 발생했습니다.", {
            position: "top-center", // 메시지 위치
            timeout: 1500, // 3초 동안 표시
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: true, // 진행 표시줄 숨기기
            closeButton: false, // X 버튼 숨기기
          });
        }
      }
    },
    // 수정 저장
    async saveEdit() {
      if (this.editedComment.trim() !== "") {
        try {
          const response = await apiClient.post("/api/Comment/comment-edit", {
            CommentId: this.comment.commentId,
            CommentContent: this.editedComment,
            UserId: localStorage.getItem("userid"),
            LoginId: localStorage.getItem("userid"),
          });

          if (response.status === 200) {
            // 수정된 내용을 부모 컴포넌트로 전달
            this.$emit("update-comment", {
              ...this.comment,
              commentContent: this.editedComment,
            });
            this.isEditing = false; // 수정 모드 해제
            this.$emit("submit-reply-success");
          }
        } catch (error) {
          this.toast.error("댓글을 삭제하는 도중 오류가 발생했습니다.", {
            position: "top-center", // 메시지 위치
            timeout: 1500, // 3초 동안 표시
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: true, // 진행 표시줄 숨기기
            closeButton: false, // X 버튼 숨기기
          });
        }
      }
    },
    async submitReply() {
      if (this.replyText.trim() !== "") {
        try {
          // API 요청 (답글 작성)
          const response = await apiClient.post(
            "/api/Comment/comment-reply-add",
            {
              ParentId: this.comment.commentId, // 부모 댓글 ID
              //Id: this.comment.Id,
              CommentContent: this.replyText, // 작성한 답글 내용
              UserId: localStorage.getItem("userid"),
              LoginId: localStorage.getItem("userid"),
            }
          );

          // API 호출이 성공하면 서버에서 받은 데이터를 UI에 반영
          if (response.status === 200) {
            this.replyText = "";
            this.showReply = false;

            // 상위 컴포넌트에 성공 이벤트 emit
            this.$emit("submit-reply-success");
          }
        } catch (error) {
          this.toast.error("답글을 전송하는 도중 오류가 발생했습니다.", {
            position: "top-center", // 메시지 위치
            timeout: 1500, // 3초 동안 표시
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: true, // 진행 표시줄 숨기기
            closeButton: false, // X 버튼 숨기기
          });
        }
      } else {
        this.toast.error("답글 내용 작성해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
      }
    },
    handleReply(newReply, parentId) {
      this.$emit("submit-reply", newReply, parentId);
    },
  },
};
</script>

<style scoped>
.comment {
  margin-bottom: 15px;
}

.replies {
  margin-left: 20px;
}

.comment-textarea {
  width: 100%;
  height: 100px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  font-size: 14px;
}
</style>
