<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">프로젝트 상세보기 (업체기준)</div>
        <div class="description">
          프로젝트건을 조회할 수 있습니다. <span class="text_red"></span>
        </div>
        <article class="right_body">
          <div class="tbl_search tbl_info">
            <div class="part_title">프로젝트상태</div>
            <table>
              <colgroup>
                <col style="width: 21%" class="col_width26" />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>상태</th>
                  <td>
                    <div :class="getStatusClass(this.status)">
                      <span>{{ getStatusText(this.status) }}</span>
                    </div>
                  </td>
                </tr>
                <tr v-if="status === 'F'">
                  <th>철회 일시</th>
                  <td>{{ withDrawalDtm }}</td>
                </tr>
                <tr v-if="status === 'F'">
                  <th>철회 사유</th>
                  <td>{{ withDrawalMemo }}</td>
                </tr>
                <tr v-if="status === 'C'">
                  <th>취소 일시</th>
                  <td>{{ cancelDtm }}</td>
                </tr>
                <tr v-if="status === 'C'">
                  <th>취소 사유</th>
                  <td>{{ cancelMemo }}</td>
                </tr>
              </tbody>
            </table>
            <div class="part_title">프로젝트정보</div>
            <table>
              <colgroup>
                <col style="width: 21%" class="col_width26" />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>프로젝트명</th>
                  <td>
                    <div class="w_250">{{ projectName }}</div>
                  </td>
                </tr>
                <tr>
                  <th>프로젝트금액<br class="only_mobile" />(vat별도)</th>
                  <td>
                    <div class="w_250">{{ formattedRealAmount }}</div>
                  </td>
                </tr>
                <tr v-if="formattedExtraAmount">
                  <th>추가금액<br class="only_mobile" />(vat별도)</th>
                  <td>
                    <div class="inner_td">
                      <div class="w_250">{{ formattedExtraAmount }}</div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>지역</th>
                  <td>
                    <div class="w_250">
                      <!-- 지역 ID와 일치하는 지역 이름을 찾아서 표시 -->
                      {{
                        areas.find((area) => area.id === selectedArea)
                          ?.areaName || "지역 정보 없음"
                      }}
                    </div>
                  </td>
                </tr>
                <tr v-if="status !== 'C'">
                  <th>
                    프로젝트시작일<br class="only_mobile" />선택하기<span
                      class="text_red"
                      >*</span
                    >
                  </th>
                  <td>
                    <div class="inner_td">
                      <!-- 선택된 프로젝트 시작</div>일이 이미 존재하거나, status가 'Y'일 경우 -->
                      <div v-if="choiceSelDate || status === 'Y'">
                        <span
                          >{{ choiceSelDate }} ({{
                            getDayOfWeek(choiceSelDate)
                          }})</span
                        >
                      </div>
                      <div v-else class="radio-group">
                        <div
                          v-for="(date, index) in selectedStartDates"
                          :key="index"
                          class="radio-wrapper"
                        >
                          <input
                            type="radio"
                            :id="'startdate-' + index"
                            v-model="selectedStartDate"
                            :value="date"
                            class="hidden-radio"
                          />
                          <label
                            :for="'startdate-' + index"
                            class="custom-label small"
                            >{{ " " + date }} ({{ getDayOfWeek(date) }})</label
                          >
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>프로젝트상세내용</th>
                  <td>
                    <div class="inner_td">
                      <div class="w_250">
                        <span v-if="detailDesc">{{ detailDesc }}</span>
                        <span v-else>미작성</span>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>투입자재목록</th>
                  <td>
                    <div v-if="showMaterialDropdown === false">
                      <span>투입자재목록 없음</span>
                    </div>
                    <!-- 선택된 자재 목록 -->
                    <div
                      v-else
                      v-for="(material, index) in selectedMaterials"
                      :key="index"
                      class="material-item"
                    >
                      <span>{{
                        material.materialsName +
                        (material.modelName
                          ? " (" + material.modelName + ")"
                          : "")
                      }}</span>
                      <span
                        ><b>{{ material.quantity }}개</b></span
                      >
                    </div>
                  </td>
                </tr>

                <!-- 투입자재가 없을 경우에는 아래 부분 안보이게 설정 -->
                <tr
                  v-if="
                    (status === 'Y' || status === 'YY') &&
                    selectedDeliveryType !== ''
                  "
                >
                  <th>자재출고방법</th>
                  <td class="inner_td">
                    <div class="w_250">
                      {{
                        selectedDeliveryType === "C"
                          ? "외주업체"
                          : selectedDeliveryType === "D"
                          ? "담당PM"
                          : ""
                      }}
                    </div>
                  </td>
                </tr>
                <tr v-if="selectedDeliveryType === 'C'">
                  <th>자재발송방법</th>
                  <td class="inner_td">
                    <div class="w_250">
                      {{
                        selectedSendType === "T"
                          ? "택배"
                          : selectedSendType === "Q"
                          ? "퀵"
                          : selectedSendType === "V"
                          ? "방문수령"
                          : ""
                      }}
                    </div>
                  </td>
                </tr>

                <tr
                  v-if="
                    selectedDeliveryType === 'C' &&
                    (selectedSendType === 'T' || selectedSendType === 'Q')
                  "
                >
                  <th>자재수령지</th>
                  <td class="td_address">
                    <div class="w_250">
                      {{ "(" + sendpostalCode + ") " }}
                      {{ sendaddress }}
                      {{ senddetailedAddress }}
                    </div>
                  </td>
                </tr>
                <tr
                  v-if="
                    selectedDeliveryType === 'C' && selectedSendType === 'T'
                  "
                >
                  <th>송장번호</th>
                  <td class="inner_td">
                    <div class="w_250">
                      {{ T_number }}
                    </div>
                  </td>
                </tr>
                <tr
                  v-if="
                    selectedDeliveryType === 'C' && selectedSendType === 'Q'
                  "
                >
                  <th>퀵 기사님 번호</th>
                  <td class="inner_td">
                    <div class="w_250">
                      {{ Q_number }}
                    </div>
                  </td>
                </tr>
                <tr
                  v-if="
                    selectedDeliveryType === 'C' && selectedSendType === 'V'
                  "
                >
                  <th>방문 수령자 이름</th>
                  <td class="inner_td">
                    <div class="w_250">
                      {{ V_name }}
                    </div>
                  </td>
                </tr>

                <tr>
                  <th>특이사항</th>
                  <td>
                    <div class="inner_td">
                      <div class="w_250">
                        <span v-if="addMemo">{{ addMemo }}</span>
                        <span v-else>미작성</span>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>마이즈 담당자<br class="only_mobile" />(영업)</th>
                  <td>
                    <div class="inner_td">
                      <div v-if="selectedSalesManager">
                        <!-- 전화번호가 존재할 경우 링크로 묶어 클릭 시 전화 걸기 -->
                        <a
                          v-if="selectedSalesManagerPhone"
                          :href="'tel:' + selectedSalesManagerPhone"
                        >
                          {{
                            salesManagers.find(
                              (manager) =>
                                manager.userId === selectedSalesManager
                            )?.adminName || "담당자 선택 안됨"
                          }}
                          <!-- 번호 출력 -->
                          {{ "( " + selectedSalesManagerPhone + " )" }}
                        </a>
                        <!-- 전화번호가 없을 경우 담당자 이름만 출력 -->
                        <span v-else>
                          {{
                            salesManagers.find(
                              (manager) =>
                                manager.userId === selectedSalesManager
                            )?.adminName || "담당자 선택 안됨"
                          }}
                        </span>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>마이즈 담당자<br class="only_mobile" />(PM)</th>
                  <td>
                    <div class="inner_td">
                      <div v-if="selectedPmManager">
                        <!-- 전화번호가 존재할 경우 링크로 묶어 클릭 시 전화 걸기 -->
                        <a
                          v-if="selectedPmManagerPhone"
                          :href="'tel:' + selectedPmManagerPhone"
                        >
                          {{
                            pmManagers.find(
                              (manager) => manager.userId === selectedPmManager
                            )?.adminName || "담당자 선택 안됨"
                          }}
                          <!-- 번호 출력 -->
                          {{ "( " + selectedPmManagerPhone + " )" }}
                        </a>
                        <!-- 전화번호가 없을 경우 담당자 이름만 출력 -->
                        <span v-else>
                          {{
                            pmManagers.find(
                              (manager) => manager.userId === selectedPmManager
                            )?.adminName || "담당자 선택 안됨"
                          }}
                        </span>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            v-if="
              (status === 'Y' || status === 'YY' || status === 'B') &&
              choiceCompanyId === companyid
            "
            class="tbl_search tbl_info"
          >
            <div class="part_title">고객사 정보</div>
            <table>
              <colgroup>
                <col style="width: 21%" />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>고객사 상호</th>
                  <td>
                    <div class="w_250">{{ customerName }}</div>
                  </td>
                </tr>
                <tr>
                  <th>상세주소</th>
                  <td class="td_address">
                    <div>
                      <div class="w_150">{{ postalCode }}</div>
                    </div>
                    <div class="w_250">{{ address }}</div>
                    <div class="w_250">{{ detailedAddress }}</div>
                  </td>
                </tr>
                <!-- <tr>
                  <th>현장 담당자 이름</th>
                  <td>
                    <div class="w_250">
                      {{ placemanagerMobile || "데이터 없음" }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>현장 담당자 연락처</th>
                  <td>
                    <div class="w_250">
                      {{ placemanagerName || "데이터 없음" }}
                    </div>
                  </td>
                </tr> -->
              </tbody>
            </table>
          </div>
          <div
            v-if="status == 'N' || status == 'F'"
            class="tbl_search tbl_info"
          >
            <div class="part_title">고객사 정보 (수주 업체만 확인 가능)</div>
            <table>
              <colgroup>
                <col style="width: 21%" />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>고객사 상호</th>
                  <td>
                    <div class="w_250">수주 후 확인 가능</div>
                  </td>
                </tr>
                <tr>
                  <th>상세주소</th>
                  <td class="td_address">
                    <div>
                      <div class="w_150">수주 후 확인 가능</div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>현장 담당자 이름</th>
                  <td class="td_address">
                    <div>
                      <div class="w_150">수주 후 확인 가능</div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>현장 담당자 연락처</th>
                  <td class="td_address">
                    <div>
                      <div class="w_150">수주 후 확인 가능</div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="tbl_search tbl_info">
            <div class="part_title accent">
              <span v-if="status === 'N'">수주하기</span>
              <span v-else>수주정보</span>
              <span v-if="choiceDtm" class="choice_name"
                >업체명: {{ choiceCompanyName }}</span
              >
            </div>
            <div
              class="acceptbox"
              v-bind:style="{ gap: isAgreed ? '15px' : '0px' }"
            >
              <!-- 동의하기 체크박스 -->
              <div
                class="checkbox-container"
                v-if="
                  status === 'N' ||
                  ((status === 'Y' || status === 'YY' || status === 'B') &&
                    choiceUserId === userId)
                "
              >
                <input
                  type="checkbox"
                  id="agreementCheckbox"
                  v-model="isAgreed"
                  :disabled="status === 'Y'"
                />
                <label for="agreementCheckbox">
                  본인은 위의 프로젝트 일정 및 내용을 충분히 숙지하였으며,
                  미이행 시 발생하는 모든 불이익에 대해 동의합니다.
                </label>
              </div>

              <table>
                <colgroup>
                  <col style="width: 23%" />
                  <col />
                </colgroup>
                <tbody>
                  <tr
                    v-if="
                      status === 'Y' ||
                      status === 'YY' ||
                      status === 'B' ||
                      status == 'C'
                    "
                  >
                    <th>수주 업체명</th>
                    <td>
                      <span>{{ choiceCompanyName || "데이터 없음" }}</span>
                    </td>
                  </tr>
                  <tr
                    v-if="
                      status === 'Y' ||
                      status === 'YY' ||
                      status === 'B' ||
                      status == 'C'
                    "
                  >
                    <th>수주 담당자</th>
                    <td>
                      <!-- status가 'N'이 아니고 choiceUserId !== userId 일 경우 마스킹 처리 -->
                      <div v-if="choiceCompanyId === companyid">
                        <span>{{ choiceUserName }}</span>
                      </div>
                      <!-- status가 'N'이거나 choiceUserId === userId 일 경우 원래 값 표시 -->
                      <div v-else>
                        <span>
                          {{
                            choiceUserName[0]
                              ? choiceUserName[0] + "**"
                              : "데이터 없음"
                          }}</span
                        >
                      </div>
                    </td>
                  </tr>
                  <tr
                    v-if="
                      status === 'Y' ||
                      status === 'YY' ||
                      status === 'B' ||
                      status == 'C'
                    "
                  >
                    <th>수주 일시</th>
                    <td>
                      <span>{{ choiceDtm || "데이터 없음" }}</span>
                    </td>
                  </tr>
                  <!-- 총 투입 예정 인원수 -->
                  <tr v-if="isAgreed">
                    <th>
                      총 투입 예정 <br class="only_mobile" />인원수<span
                        class="text_red"
                        >*</span
                      >
                    </th>
                    <td>
                      <!-- status가 'Y'일 경우 텍스트로 표시 -->
                      <div
                        v-if="
                          status === 'Y' ||
                          status === 'YY' ||
                          status === 'B' ||
                          status === 'F' ||
                          status === 'C'
                        "
                      >
                        <span>{{
                          totalPersonnel
                            ? totalPersonnel + " 명"
                            : "데이터 없음"
                        }}</span>
                      </div>
                      <!-- status가 'N'일 경우 input box로 표시 -->
                      <div v-else>
                        <input
                          type="number"
                          class="w_250"
                          v-model="totalPersonnel"
                          ref="totalPersonnel"
                          placeholder="총 투입 예정 인원수를 입력해주세요"
                          @input="filterNonNumeric"
                        />
                      </div>
                    </td>
                  </tr>
                  <!-- 투입 인원 프로젝트 담당자 이름 -->
                  <tr v-if="isAgreed">
                    <th>
                      프로젝트<br class="only_mobile" />담당자<span
                        class="text_red"
                        >*</span
                      >
                    </th>
                    <td>
                      <!-- status가 'Y'일 경우 텍스트로 표시 -->
                      <div
                        v-if="
                          status === 'Y' ||
                          status === 'YY' ||
                          status === 'B' ||
                          status === 'F' ||
                          status === 'C'
                        "
                      >
                        <!-- status가 'N'이 아니고 choiceUserId !== userId 일 경우 마스킹 처리 
                       v-if="choiceCompanyId === companyid"-->
                        <div
                          v-if="status !== 'N' && choiceCompanyId !== companyid"
                        >
                          <span>{{
                            representativeName[0]
                              ? representativeName[0] + "**"
                              : "데이터 없음"
                          }}</span>
                          <!-- 성 + '**' 형태로 마스킹 -->
                        </div>
                        <!-- status가 'N'이거나 choiceUserId === userId 일 경우 원래 값 표시 -->
                        <div v-else>
                          <span>{{ representativeName }}</span>
                        </div>
                      </div>
                      <!-- status가 'N'일 경우 input box로 표시 -->
                      <div v-else>
                        <input
                          type="text"
                          class="w_250"
                          v-model="representativeName"
                          ref="representativeName"
                          placeholder="프로젝트 담당자를 입력해주세요"
                        />
                      </div>
                    </td>
                  </tr>
                  <!-- 연락처 -->
                  <tr v-if="isAgreed">
                    <th>
                      프로젝트<br class="only_mobile" />담당자 연락처<span
                        class="text_red"
                        >*</span
                      >
                    </th>
                    <td>
                      <!-- status가 'Y'일 경우 텍스트로 표시 -->
                      <div
                        v-if="
                          status === 'Y' ||
                          status === 'YY' ||
                          status === 'B' ||
                          status === 'F' ||
                          status === 'C'
                        "
                      >
                        <div
                          v-if="status !== 'N' && choiceCompanyId !== companyid"
                        >
                          <span>{{
                            representativeContact
                              ? maskPhoneNumber(representativeContact)
                              : "데이터 없음"
                          }}</span>
                        </div>
                        <!-- status가 'N'이거나 choiceUserId === userId 일 경우 원래 값 표시 -->
                        <div v-else>
                          <span>{{ representativeContact }}</span>
                        </div>
                      </div>
                      <!-- status가 'N'일 경우 input box로 표시 -->
                      <div v-else>
                        <input
                          type="text"
                          class="w_250"
                          v-model="representativeContact"
                          ref="representativeContact"
                          @input="formatPhoneNumber"
                          placeholder="연락처를 입력해주세요"
                        />
                      </div>
                    </td>
                  </tr>
                  <!-- 세금계산서 발급일 -->
                  <tr v-if="status === 'YY' && choiceUserId === userId">
                    <th>세금계산서 발급일<span class="text_red">*</span></th>
                    <td>
                      <!-- selectedBillDate 값이 있으면 문구로 표시 -->
                      <!-- <div v-if="selectedBillDate">
                      <span>{{ selectedBillDate }}</span>
                    </div> -->
                      <!--  v-else 값이 없으면 입력 필드로 표시 -->
                      <div>
                        <input
                          type="date"
                          class="w_250"
                          v-model="selectedBillDate"
                        />
                      </div>
                    </td>
                  </tr>
                  <!-- 세금계산서 발급일 -->
                  <tr
                    v-if="orderStatus === 'B' && choiceCompanyId === companyid"
                  >
                    <th>세금계산서 발급일<span class="text_red">*</span></th>
                    <td>
                      <!-- status가 'Y'일 경우 텍스트로 표시 -->
                      <div>
                        <span>{{ selectedBillDate }}</span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="btn_bottom_a">
            <button
              v-if="status === 'N'"
              type="button"
              class="on"
              @click="saveConstruction"
            >
              수주하기
            </button>
            <button
              v-if="status === 'YY' && choiceUserId === userId"
              type="button"
              class="on"
              @click="saveBillConstruction"
            >
              저장
            </button>
            <button
              v-if="
                status === 'Y' &&
                choiceUserId === userId &&
                materialReceiveYN === 'N' &&
                selectedDeliveryType !== ''
              "
              type="button"
              @click="saveMaterialConstruction"
            >
              자재수령완료
            </button>
            <button type="button" @click="goToConstructionList">목록</button>
          </div>
        </article>
      </div>
      <!-- 삼성인터넷브라우저에서 안되서 원인 파악하다가 스타일문제로 확인! 임시방편임 -->
      <div style="margin-top: 100px"></div>
    </div>
  </div>
</template>

<script>
import apiClient from "@/apiClient"; // 설정 파일에서 가져온 axios 인스턴스
import { useToast } from "vue-toastification";
//import qs from "qs"; // Query string 라이브러리

export default {
  name: "ProjectCompanyView",
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      showMaterialDropdown: true, // 자재 선택 dropdown을 표시할지 여부
      checkStatusList: [],
      isAgreed: false, // 동의 체크박스 상태를 저장하는 변수
      selectedArea: "0", // 전체보기 기본 값 설정
      areas: [], // API에서 가져온 지역 목록을 저장할 배열
      materials: [], // 자재 목록을 저장할 배열
      selectedMaterial: "", // 선택된 자재를 저장할 변수
      selectedMaterials: [], // 선택된 자재 목록을 저장할 배열
      selectedStartDate: "", // 선택된 프로젝트 시작일
      selectedStartDates: [], // 선택된 프로젝트 시작일 목록
      postalCode: "", // 우편번호 입력값
      address: "", // 주소 입력값
      detailedAddress: "", // 상세주소 입력값
      salesManagers: [], // 영업 담당자 목록을 저장할 배열
      selectedSalesManager: "", // 선택된 영업 담당자
      selectedSalesManagerPhone: "", // 선택된 영업 담당자 핸드폰번호
      pmManagers: [], // PM 담당자 목록을 저장할 배열
      selectedPmManager: "", // 선택된 PM 담당자
      selectedPmManagerPhone: "", // 선택된 PM 담당자 핸드폰번호
      extraAmount: 0, // 실제 금액을 저장하는 변수
      formattedExtraAmount: "", // ','가 포함된 금액을 저장하는 변수
      formattedRealAmount: "", // ','가 포함된 금액을 저장하는 변수
      status: "",
      orderStatus: "",
      choiceSelDate: "", // 선택된 프로젝트 시작일 값 추가
      selectedBillDate: "",
      createdUserId: "",
      createdUserName: "",
      selectedCheckboxDates: [], // 체크박스에서 선택된 프로젝트 시작일 목록
      daysOfWeek: ["일", "월", "화", "수", "목", "금", "토"], // 요일 배열
      totalPersonnel: "", // 총 투입 예정 인원수를 저장하는 변수
      representativeName: "", // 프로젝트 담당자 이름을 저장하는 변수
      representativeContact: "", // 프로젝트 담당자 연락처를 저장하는 변수
      choiceDtm: "",
      choiceCompanyId: "",
      choiceCompanyName: "",
      choiceUserId: "",
      choiceUserName: "",
      placemanagerMobile: "",
      placemanagerName: "",

      withDrawalDtm: "",
      withDrawalMemo: "",
      cancelDtm: "",
      cancelMemo: "",

      // 자재발송관련
      selectedDeliveryType: "",
      selectedSendType: "",
      sendpostalCode: "",
      sendaddress: "",
      senddetailedAddress: "",
      T_number: "",
      Q_number: "",
      V_name: "",
      materialReceiveYN: "",
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  computed: {
    userId() {
      return this.$store.getters.getUserid;
    },
    userName() {
      return this.$store.getters.getUsername;
    },
    userType() {
      return this.$store.getters.getUsertype;
    },
    userPermission() {
      return this.$store.getters.getUserpermission;
    },
    companyid() {
      return this.$store.getters.getcompanyid;
    },
    areaName() {
      // 지역 ID를 기반으로 실제 표시할 이름 반환
      return (
        this.areas.find((area) => area.id === this.selectedArea)?.areaName ||
        "Unknown Area"
      );
    },
    mizeSalesUserName() {
      // 영업 담당자 ID를 기반으로 실제 표시할 이름 반환
      return (
        this.salesManagers.find(
          (manager) => manager.userId === this.selectedSalesManager
        )?.adminName || "Unknown Sales Manager"
      );
    },
    mizePMUserName() {
      // PM 담당자 ID를 기반으로 실제 표시할 이름 반환
      return (
        this.pmManagers.find(
          (manager) => manager.userId === this.selectedPmManager
        )?.adminName || "Unknown PM Manager"
      );
    },
  },
  methods: {
    filterNonNumeric(event) {
      // 숫자 이외의 입력을 막음
      event.target.value = event.target.value.replace(/[^0-9]/g, "");
      this.totalPersonnel = event.target.value; // 숫자로만 값을 업데이트
    },
    // 연락처를 마스킹 처리하는 함수
    maskPhoneNumber(phone) {
      if (!phone) return "";
      // 010-****-**** 형태로 마스킹
      return phone.replace(/(\d{3})-\d{4}-\d{4}/, "$1-****-****");
    },
    // <!-- 프로젝트건 상태 (수주전-'N'/수주완료-'Y'/프로젝트완료-'YY'/계산서발급완료-'B'/수주철회-'F'/프로젝트취소-'C') -->
    // 상태에 따른 문구 반환
    getStatusText(status) {
      switch (status) {
        case "N":
          return "수주전";
        case "Y":
          return "수주완료";
        case "YY":
          return "프로젝트완료";
        case "B":
          return "계산서발급완료";
        case "F":
          return "수주철회";
        case "C":
          return "프로젝트취소";
        case "E":
          return "정산완료";
        default:
          return "알 수 없음";
      }
    },
    getStatusClass(status) {
      switch (status) {
        case "N":
          return "stat back_green"; // 수주전
        case "Y":
          return "stat back_blue"; // 수주완료
        case "YY":
          return "stat back_grey"; // 프로젝트완료
        case "B":
          return "stat back_red"; // 계산서발급완료
        case "F":
          return "stat back_orange"; // 수주철회
        case "C":
          return "stat back_lightgrey"; // 프로젝트취소
        case "E":
          return "stat back_purple"; // 정산완료
        default:
          return "stat back_default"; // 기본 값
      }
    },
    // 전화번호 포맷팅 함수
    formatPhoneNumber(event) {
      let input = event.target.value.replace(/\D/g, ""); // 숫자 이외의 문자 제거

      // 변환된 값을 담을 변수 선언
      let formattedNumber = "";

      if (input.length <= 3) {
        formattedNumber = input;
      } else if (input.length <= 7) {
        formattedNumber = input.slice(0, 3) + "-" + input.slice(3);
      } else {
        formattedNumber =
          input.slice(0, 3) +
          "-" +
          input.slice(3, 7) +
          "-" +
          input.slice(7, 11);
      }

      // 포맷팅된 값을 다시 반영
      this.representativeContact = formattedNumber;
    },
    // 날짜 문자열을 받아 해당 요일을 반환
    getDayOfWeek(date) {
      const day = new Date(date).getDay();
      return this.daysOfWeek[day];
    },
    selectPmManager(pmmanagerId) {
      this.selectedPmManager = pmmanagerId;
    },
    async fetchPmManagers() {
      try {
        const searchParam = {
          PageSize: 100,
          Page: "1",
        };

        const response = await apiClient.post(
          "/api/User/pm-user-list",
          searchParam
        ); // 예시 API 엔드포인트
        if (response.status === 200) {
          const data = JSON.parse(response.data);
          this.pmManagers = Array.isArray(data.data) ? data.data : [];
          console.log(this.pmManagers);
        }
      } catch (error) {
        console.error("Error fetching pm managers:", error);
      }
    },
    selectSalesManager(salesmanagerId) {
      this.selectedSalesManager = salesmanagerId;
    },
    async fetchSalesManagers() {
      try {
        const searchParam = {
          PageSize: 100,
          Page: "1",
        };

        const response = await apiClient.post(
          "/api/User/sales-user-list",
          searchParam
        ); // 예시 API 엔드포인트
        if (response.status === 200) {
          const data = JSON.parse(response.data);
          this.salesManagers = Array.isArray(data.data) ? data.data : [];
          console.log(this.salesManagers);
        }
      } catch (error) {
        console.error("Error fetching sales managers:", error);
      }
    },
    async fetchMaterials() {
      try {
        const searchParam = {
          PageSize: 100,
          Page: "1",
        };

        const response = await apiClient.post(
          "/api/Materials/all-materials-list",
          searchParam
        );
        if (response.status === 200) {
          const data = JSON.parse(response.data);
          this.materials = Array.isArray(data.data) ? data.data : [];
          console.log(this.materials);
        }
      } catch (error) {
        console.error("Error fetching materials:", error);
      }
    },
    goToConstructionList() {
      this.$router.push({ path: "/projects/companylist" });
    },

    async fetchAreas() {
      try {
        const searchParam = {
          PageSize: 100,
          Page: "1",
        };
        const response = await apiClient.post(
          "/api/Area/all-area-list",
          searchParam
        );
        if (response.status === 200) {
          const data = JSON.parse(response.data);
          this.areas = Array.isArray(data.data) ? data.data : [];
          console.log(this.areas);
        }
      } catch (error) {
        console.error("Error fetching areas:", error);
      }
    },
    async saveMaterialConstruction() {
      const confirmation = confirm(
        "해당 프로젝트의 자재 수령을 완료 처리하시겠습니까?"
      );
      if (confirmation) {
        try {
          // 데이터 저장을 위한 API 호출 로직
          const response = await apiClient.post(
            "/api/Construction/construction-material-update",
            {
              Id: this.id,
              LoginId: localStorage.getItem("userid"),
              // 필요한 다른 필드 추가
            }
          );

          if (response.status === 200) {
            this.toast.success("자재수령 완료가 성공적으로 저장되었습니다.", {
              position: "top-center", // 메시지 위치
              timeout: 1500, // 3초 동안 표시
              closeOnClick: true,
              pauseOnHover: true,
              hideProgressBar: true, // 진행 표시줄 숨기기
              closeButton: false, // X 버튼 숨기기
            });

            this.goToConstructionList(); // 목록으로 이동
            //this.$router.go(0); // 현재 페이지를 새로고침
          }
        } catch (error) {
          console.error("Error saving construction data:", error);
          this.toast.error("자재수령 완료 저장 중 오류가 발생했습니다.", {
            position: "top-center", // 메시지 위치
            timeout: 1500, // 3초 동안 표시
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: true, // 진행 표시줄 숨기기
            closeButton: false, // X 버튼 숨기기
          });
        }
      }
    },
    async saveBillConstruction() {
      // 세금계산서 발급일이 선택되었는지 확인
      if (!this.selectedBillDate) {
        this.toast.error("세금계산서 발급일 선택해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return false;
      }

      try {
        // 데이터 저장을 위한 API 호출 로직
        const response = await apiClient.post(
          "/api/Construction/construction-billdate-update",
          {
            Id: this.id,
            BillDate: this.selectedBillDate,
            LoginId: localStorage.getItem("userid"),
            // 필요한 다른 필드 추가
          }
        );

        if (response.status === 200) {
          this.toast.success("세금계산서 발급일이 성공적으로 저장되었습니다.", {
            position: "top-center", // 메시지 위치
            timeout: 1500, // 3초 동안 표시
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: true, // 진행 표시줄 숨기기
            closeButton: false, // X 버튼 숨기기
          });

          this.goToConstructionList(); // 목록으로 이동
          //this.$router.go(0); // 현재 페이지를 새로고침
        }
      } catch (error) {
        console.error("Error saving construction data:", error);
        this.toast.error("세금계산서 발급일이 저장 중 오류가 발생했습니다.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
      }
    },
    // 수주하기 저장
    async saveConstruction() {
      if (this.checkFields()) {
        // 프로젝트를 수주하시겠습니까? 확인 메시지
        const confirmation = confirm("해당 프로젝트를 수주하시겠습니까?");
        if (confirmation) {
          // 확인을 누르면 데이터 저장 로직 실행
          try {
            const checkResponse = await apiClient.post(
              "/api/Construction/construction-check",
              {
                Id: this.id,
                LoginId: localStorage.getItem("userid"),
              }
            );

            if (checkResponse.status == 200) {
              console.log(checkResponse.data);
              const data = JSON.parse(checkResponse.data);
              console.log(data);
              this.checkStatusList = Array.isArray(data.data) ? data.data : [];

              if (this.checkStatusList[0].checkStatus === "Y") {
                this.toast.error("이미 다른 업체가 수주처리 하였습니다.", {
                  position: "top-center", // 메시지 위치
                  timeout: 1500, // 3초 동안 표시
                  closeOnClick: true,
                  pauseOnHover: true,
                  hideProgressBar: true, // 진행 표시줄 숨기기
                  closeButton: false, // X 버튼 숨기기
                });

                this.$router.go(0); // 현재 페이지를 새로고침
              } else {
                // 데이터 저장을 위한 API 호출 로직
                const response = await apiClient.post(
                  "/api/Construction/construction-accept",
                  {
                    Id: this.id,
                    ChoiceSelDate: this.selectedStartDate,
                    TotalPeople: this.totalPersonnel,
                    ManagerUserName: this.representativeName,
                    ManagerUserPhone: this.representativeContact,
                    LoginId: localStorage.getItem("userid"),
                    CreatedUserId: this.createdUserId,
                    CreatedUserName: this.createdUserName,
                    CustomerName: this.customerName,
                    ProjectName: this.projectName,
                    Price: this.formattedRealAmount,
                    AddPrice: this.formattedExtraAmount,
                    AreaId: this.selectedArea,
                    AreaName: this.areaName,
                    ZipCode: this.postalCode,
                    Addr1: this.address,
                    Addr2: this.detailedAddress,
                    DetailContent: this.detailDesc,
                    EtcMemo: this.addMemo,
                    ChoiceCompanyId: this.choiceCompanyId,
                    ChoiceCompanyName: this.choiceCompanyName,
                    ChoiceUserId: this.choiceUserId,
                    ChoiceUserName: this.choiceUserName,
                    MizeSalesUserId: this.mizeSalesUserName,
                    MizePMUserId: this.mizePMUserName,
                    Materials: this.selectedMaterials.map((mat) => ({
                      MaterialId: mat.id,
                      MaterialCnt: mat.quantity,
                      MaterialName: mat.materialsName,
                    })),
                  }
                );

                if (response.status === 200) {
                  this.toast.success("프로젝트 수주처리가 완료되었습니다.", {
                    position: "top-center", // 메시지 위치
                    timeout: 1500, // 3초 동안 표시
                    closeOnClick: true,
                    pauseOnHover: true,
                    hideProgressBar: true, // 진행 표시줄 숨기기
                    closeButton: false, // X 버튼 숨기기
                  });
                  this.$router.go(0); // 현재 페이지를 새로고침
                }
              }
            }
          } catch (error) {
            console.error("Error saving construction data:", error);
            this.toast.error("프로젝트 수주처리 중 오류가 발생했습니다.", {
              position: "top-center", // 메시지 위치
              timeout: 1500, // 3초 동안 표시
              closeOnClick: true,
              pauseOnHover: true,
              hideProgressBar: true, // 진행 표시줄 숨기기
              closeButton: false, // X 버튼 숨기기
            });
          }
        }
      }
    },
    async fetchConstructionData() {
      try {
        this.isFetchingData = true; // 데이터 불러오기 시작
        const response = await apiClient.post(
          "/api/Construction/construction-info",
          {
            Id: this.id,
            LoginId: localStorage.getItem("userid"),
          }
        );

        if (response.status === 200) {
          const data = JSON.parse(response.data);
          console.log(data);

          this.constructionInfo = Array.isArray(data.data) ? data.data : [];
          console.log(
            "☆☆☆☆☆☆☆☆this.constructionInfo[0]☆☆☆☆☆☆☆☆☆" +
              this.constructionInfo[0]
          );

          this.projectName = this.constructionInfo[0].projectName;
          this.formattedRealAmount = this.constructionInfo[0].price;
          this.formattedExtraAmount =
            this.constructionInfo[0].addPrice == "0"
              ? ""
              : this.constructionInfo[0].addPrice;
          this.selectedArea = this.constructionInfo[0].areaId;
          // 시작일 부분 split 해서 ui 표현해야 함
          this.formattedStartDates = this.constructionInfo[0].startDate;
          this.selectedStartDates = this.formattedStartDates.split("^"); // 날짜를 배열로 변환

          this.postalCode = this.constructionInfo[0].zipCode;
          this.address = this.constructionInfo[0].addr1;
          this.detailedAddress = this.constructionInfo[0].addr2;
          this.detailDesc = this.constructionInfo[0].detailContent;
          this.status = this.constructionInfo[0].status;
          this.orderStatus = this.constructionInfo[0].orderStatus;
          //alert(this.orderStatus);
          //this.selectedMaterials = this.constructionInfo[0].
          this.choiceSelDate = data.data[0].choiceSelDate; // 프로젝트 시작일 선택 값 설정
          this.addMemo = this.constructionInfo[0].etcMemo;
          this.selectedSalesManager = this.constructionInfo[0].mizeSalesUserId;
          this.selectedPmManager = this.constructionInfo[0].mizePMUserId;

          this.selectedSalesManagerPhone =
            this.constructionInfo[0].mizeSalesUserPhone;
          this.selectedPmManagerPhone =
            this.constructionInfo[0].mizePMUserPhone;

          this.customerName = this.constructionInfo[0].customerName;
          this.totalPersonnel = this.constructionInfo[0].totalPeople;
          this.representativeName = this.constructionInfo[0].managerUserName;
          this.representativeContact =
            this.constructionInfo[0].managerUserPhone;
          this.selectedBillDate = this.constructionInfo[0].billDate;

          this.createdUserId = this.constructionInfo[0].createdUserId;
          this.createdUserName = this.constructionInfo[0].createdUserName;

          this.choiceDtm = this.constructionInfo[0].choiceDtm;
          this.choiceCompanyId = this.constructionInfo[0].choiceCompanyId;
          this.choiceCompanyName = this.constructionInfo[0].choiceCompanyName;
          this.choiceUserId = this.constructionInfo[0].choiceUserId;
          this.choiceUserName = this.constructionInfo[0].choiceUserName;

          this.placemanagerMobile = this.constructionInfo[0].placeManagerMobile;
          this.placemanagerName = this.constructionInfo[0].placeManagerName;
          this.showMaterialDropdown =
            this.constructionInfo[0].materialsNoYN === "Y" ? false : true;

          this.withDrawalDtm = this.constructionInfo[0].withDrawalDtm;
          this.withDrawalMemo = this.constructionInfo[0].withDrawalMemo;

          this.cancelDtm = this.constructionInfo[0].canceledDtm;
          this.cancelMemo = this.constructionInfo[0].cancelMemo;

          this.selectedDeliveryType = this.constructionInfo[0].deliveryType;
          this.selectedSendType = this.constructionInfo[0].sendType;
          this.sendpostalCode = this.constructionInfo[0].sendZipCode;
          this.sendaddress = this.constructionInfo[0].sendAddr1;
          this.senddetailedAddress = this.constructionInfo[0].sendAddr2;
          this.T_number = this.constructionInfo[0].tNumber;
          this.Q_number = this.constructionInfo[0].qNumber;
          this.V_name = this.constructionInfo[0].vName;

          this.materialReceiveYN = this.constructionInfo[0].materialReceiveYN;

          if (this.status === "N") this.isAgreed = false;
          else if (this.status === "Y") this.isAgreed = true;
          else if (this.status === "YY") this.isAgreed = true;
          else if (this.status === "B") this.isAgreed = true;

          // 프로젝트 정보에 자재 목록이 포함되어 있는 경우 처리
          if (this.constructionInfo[0].materials) {
            this.selectedMaterials = this.constructionInfo[0].materials.map(
              (material) => ({
                id: material.materialId,
                materialsName: material.materialName,
                modelName: material.modelName || "", // 모델명이 있을 경우 포함
                quantity: material.materialCnt || 1, // 수량 정보가 없으면 기본 수량 1
              })
            );
          }
          // console.log(this.meetingroomInfo[0].timeSettings);
        }
      } catch (error) {
        console.error("Error fetching meetingroomInfo data:", error);
      } finally {
        this.isFetchingData = false; // 데이터 불러오기 완료
      }
    },
    checkFields() {
      // 프로젝트 시작일이 선택되지 않았는지 확인
      if (!this.selectedStartDate) {
        this.toast.error("프로젝트 시작일을 선택해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return false;
      }

      // 동의 체크박스가 체크되지 않았을 경우 알림
      if (!this.isAgreed) {
        this.toast.error("본인 동의에 체크 해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return;
      }

      // 입력 값이 올바른지 확인
      if (!this.totalPersonnel || this.totalPersonnel <= 0) {
        this.$refs.totalPersonnel.focus(); // 포커스 이동
        this.toast.error("총 투입 예정 인원수를 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return;
      }
      if (!this.representativeName) {
        this.$refs.representativeName.focus(); // 포커스 이동
        this.toast.error("프로젝트 담당자를 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return;
      }
      if (!this.representativeContact) {
        this.$refs.representativeContact.focus(); // 포커스 이동
        this.toast.error("프로젝트 담당자 연락처를 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return;
      }
      // 연락처 유효성 검사 (10자리 또는 11자리의 숫자로 구성)
      const cleanedContact = this.representativeContact.replace(/\D/g, ""); // '-' 제거 후 숫자만 남김
      if (cleanedContact.length !== 10 && cleanedContact.length !== 11) {
        this.$refs.representativeContact.focus(); // 포커스 이동
        this.toast.error(
          "유효한 연락처를 입력해주세요. (10자리 또는 11자리).",
          {
            position: "top-center", // 메시지 위치
            timeout: 1500, // 3초 동안 표시
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: true, // 진행 표시줄 숨기기
            closeButton: false, // X 버튼 숨기기
          }
        );
        return false;
      }
      return true;
    },
  },
  mounted() {
    this.fetchAreas(); // 컴포넌트가 마운트될 때 회사 정보를 가져옵니다.
    this.fetchMaterials(); // 자재 데이터를 가져오기
    this.fetchSalesManagers(); // 영업 담당자 목록을 가져옴
    this.fetchPmManagers(); // 영업 담당자 목록을 가져옴
    this.fetchConstructionData(); // 프로젝트 상세정보
  },
};
</script>

<style scoped></style>
