<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">알림 목록</div>
        <div class="description">
          <span>
            나에게 온 알림을 확인합니다.<br />
            14일이 지난 알림은 삭제됩니다.
          </span>
        </div>
        <article class="right_body">
          <!--검색툴 -->
          <div class="tbl_search seach_mobile">
            <table>
              <colgroup>
                <col style="width: 15%" />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>확인여부</th>
                  <td>
                    <div class="inner_td">
                      <button
                        type="button"
                        :class="{ on: SearchConfirmStatus === 'N' }"
                        @click="updateConfirmStatus('N')"
                      >
                        미확인알림
                      </button>
                      <button
                        type="button"
                        :class="{ on: SearchConfirmStatus === 'A' }"
                        @click="updateConfirmStatus('A')"
                      >
                        모든알림
                      </button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>카테고리별</th>
                  <td>
                    <div class="inner_td">
                      <button
                        type="button"
                        :class="{ on: SearchStatus === 'A' }"
                        @click="updateStatus('A')"
                      >
                        전체
                      </button>
                      <button
                        type="button"
                        :class="{ on: SearchStatus === 'P' }"
                        @click="updateStatus('P')"
                      >
                        프로젝트
                      </button>
                      <!-- <button
                        type="button"
                        :class="{ on: SearchStatus === 'B' }"
                        @click="updateStatus('B')"
                      >
                        정산
                      </button> -->
                      <button
                        type="button"
                        :class="{ on: SearchStatus === 'M' }"
                        @click="updateStatus('M')"
                      >
                        담당자
                      </button>
                      <button
                        type="button"
                        :class="{ on: SearchStatus === 'N' }"
                        @click="updateStatus('N')"
                      >
                        공지사항
                      </button>
                    </div>
                  </td>
                </tr>
                <!-- <tr>
                  <th>게시판</th>
                  <td>
                    <div class="inner_td">
                      <button
                        type="button"
                        :class="{ on: SearchBoardStatus === '' }"
                        @click="updateBoardStatus()"
                      >
                        게시판이름
                      </button>
                    </div>
                  </td>
                </tr> -->
                <tr>
                  <th>직원별보기</th>
                  <td>
                    <div class="inner_td">
                      <button
                        v-for="employee in salesEmployees"
                        :key="employee.userId"
                        type="button"
                        :class="{
                          on: selectedEmployee === employee.userId,
                        }"
                        @click="selectEmployee(employee.userId)"
                      >
                        {{ employee.adminName }}
                      </button>
                    </div>
                  </td>
                </tr>
                <tr style="display: none">
                  <th>키워드검색</th>
                  <td>
                    <div class="inner_td">
                      <select v-model="SearchType">
                        <option value="alarmnoticeSubject">제목</option>
                        >
                      </select>
                      <input
                        type="text"
                        v-model="SearchKey"
                        class="pop_text"
                        placeholder="검색어를 입력해주세요"
                        @keyup.enter="handleSearchKeyEnter"
                      />
                      <button type="button" @click="handleSearchKeyEnter">
                        검색
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- //검색툴 -->
          <div class="count_page">
            <div class="count_num">
              <span>총</span>
              <span
                class="accent_color bold"
                style="margin-left: 2px; margin-right: 2px"
              >
                {{ this.totalalarmNotices }}
              </span>
              <span>건</span>
              <select v-model="itemsPerPage" @change="handleItemsPerPageChange">
                <option selected="selected" value="5">5</option>
                <option value="10">10</option>
                <option value="30">30</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="300">300</option>
                <option value="500">500</option>
                <option value="1000">1000</option>
              </select>
              <span>건씩</span>
            </div>
          </div>
          <div class="right_contents">
            <!--목록-->
            <div
              v-if="alarmList.length > 0"
              class="tbl_list table_m mobile_table"
            >
              <table>
                <colgroup>
                  <col />
                  <col style="width: 20%" />
                </colgroup>
                <thead class="no_mobile">
                  <tr>
                    <th>
                      <a href="###">내용</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">작성일시</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <!-- 일반 공지사항 -->
                  <tr
                    v-for="alarmnotice in paginatedalarmNotices"
                    :key="alarmnotice.noticeId"
                    :class="{ 'disabled-row': !alarmnotice.commentId }"
                    @click="
                      !alarmnotice.commentId
                        ? null
                        : handleNotificationClick(
                            alarmnotice.id,
                            alarmnotice.commentId,
                            alarmnotice.readId,
                            $index,
                            alarmnotice.leftMenuName
                          )
                    "
                    style="cursor: pointer"
                    class="noti_tr"
                  >
                    <td class="detail_page_a o2 o_title noti_row">
                      <div class="board_titlebox noti_row_wrapper">
                        <img
                          :src="
                            alarmnotice.userPhotoName
                              ? `https://mizecloud-partners.co.kr/employee_img/${alarmnotice.userPhotoName}`
                              : 'https://mizecloud-partners.co.kr/employee_img/default.png'
                          "
                        />
                        <div>
                          <span v-if="!alarmnotice.commentId"> (삭제됨)</span>

                          <span>
                            <span class="noti_name">{{
                              alarmnotice.userName
                            }}</span
                            >님이
                            <span class="noti_name">{{
                              alarmnotice.commentSubject
                            }}</span>
                            <template v-if="alarmnotice.parentId">
                              <span class="noti_accent">댓글</span>에
                              <span class="noti_accent">댓글</span>을
                              남겼습니다.
                            </template>
                            <template v-else>
                              <span class="noti_accent">게시물</span>에
                              <span class="noti_accent">댓글</span>을
                              남겼습니다.
                            </template>
                          </span>
                          <span class="noti_location"
                            >{{ alarmnotice.leftMenuName }} /
                            {{ getTopMenuText(alarmnotice.categoryType) }}</span
                          >
                        </div>
                      </div>
                    </td>
                    <td class="name o5 noti_datebox">
                      <div class="noti_date">
                        <span>{{
                          alarmnotice.createdDtm
                            ? alarmnotice.createdDtm
                            : "없음"
                        }}</span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-else class="tbl_list table_m mobile_table">
              <table>
                <colgroup>
                  <col />
                  <col style="width: 18%" />
                  <!-- <col style="width: 10%" /> -->
                </colgroup>
                <thead class="no_mobile">
                  <tr>
                    <th>
                      <a href="###">내용</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">작성일시</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="no_data">
                    <td colspan="2">데이터가 없습니다</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!--//목록-->
          </div>
          <!-- 페이징 -->
          <div class="page_num">
            <div class="pageing">
              <a
                href="###"
                v-if="hasPrevPageGroup"
                @click.prevent="prevPageGroup"
                ><img src="@/images/front_n.png" alt="처음페이지"
              /></a>
              <a href="###" v-if="hasPrevPage" @click.prevent="prevPage"
                ><img src="@/images/prev_n.png" alt="이전페이지"
              /></a>
              <a
                v-for="page in pageGroup"
                :key="page"
                href="###"
                :class="{ on: currentPage === page }"
                @click.prevent="goToPage(page)"
              >
                {{ page }}
              </a>
              <a href="###" v-if="hasNextPage" @click.prevent="nextPage"
                ><img src="@/images/next_n.png" alt="다음페이지"
              /></a>
              <a
                href="###"
                v-if="hasNextPageGroup"
                @click.prevent="nextPageGroup"
                ><img src="@/images/end_n.png" alt="마지막페이지"
              /></a>
            </div>
          </div>
        </article>
      </div>
      <!-- 삼성인터넷브라우저에서 안되서 원인 파악하다가 스타일문제로 확인! 임시방편임 -->
      <div style="margin-top: 200px"></div>
    </div>
  </div>
</template>

<script>
import apiClient from "@/apiClient"; // 설정 파일에서 가져온 axios 인스턴스
import { useToast } from "vue-toastification";

export default {
  name: "AlarmNoticesList",
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      alarmList: [], // 공지사항 목록 데이터를 저장할 배열
      selectedalarmNotices: [], // 선택된 담당자들의 id를 저장할 배열
      allSelected: false, // thead 체크박스 상태
      currentPage: 1, // 현재 페이지
      itemsPerPage: 10, // 페이지 당 항목 수
      pageGroupSize: 5, // 하단 페이지 나오는 수 ( 1-5 / 6-10 > 5개씩 )
      SearchType: "alarmnoticeSubject", // 검색 키 (dropdownlist)
      SearchKey: "", // 검색 textbox 값

      SearchConfirmStatus: "N", // 확인여부 미확인알림 상태
      SearchStatus: "A", // 카테고리 상태별값
      SearchBoardStatus: "", // 게시판 상태
      SearchEmployeeStatus: "A", // 직원별보기 상태

      OrderType: "", // 이름 오름차순
      totalalarmNotices: 0, // 총 공지사항 수

      salesEmployees: [], // 영업 담당자 목록을 저장할 배열
      selectedEmployee: "All", // 선택된 영업 담당자
    };
  },
  computed: {
    noticeLink() {
      return (noticeId) => {
        return this.userType === "M"
          ? { name: "NoticesView", params: { id: noticeId } }
          : { name: "NoticesCompanyView", params: { id: noticeId } };
      };
    },
    userId() {
      return this.$store.getters.getUserid;
    },
    userName() {
      return this.$store.getters.getUsername;
    },
    userType() {
      return this.$store.getters.getUsertype;
    },
    userPermission() {
      return this.$store.getters.getUserpermission;
    },
    // 현재 페이지에 해당하는 회사 목록을 반환
    paginatedalarmNotices() {
      if (!Array.isArray(this.alarmList)) {
        return [];
      }

      const start = 0;
      const end = this.itemsPerPage;
      return this.alarmList.slice(start, end);
    },
    // 총 페이지 수 계산
    totalPages() {
      return Math.ceil(this.totalalarmNotices / this.itemsPerPage);
    },
    // 현재 페이지 그룹 계산
    pageGroup() {
      const start =
        Math.floor((this.currentPage - 1) / this.pageGroupSize) *
          this.pageGroupSize +
        1;
      const end = Math.min(start + this.pageGroupSize - 1, this.totalPages);
      const pages = [];
      for (let i = start; i <= end; i++) {
        pages.push(i);
      }
      return pages;
    },
    hasPrevPageGroup() {
      //return this.currentPage > this.pageGroupSize;
      return this.currentPage > 1;
    },
    hasNextPageGroup() {
      //return this.currentPage + this.pageGroupSize <= this.totalPages;
      return this.currentPage < this.totalPages;
    },
    hasPrevPage() {
      return this.currentPage > 1;
    },
    hasNextPage() {
      return this.currentPage < this.totalPages;
    },
  },

  methods: {
    updateQuery() {
      this.$router.replace({
        query: {
          page: this.currentPage,
          itemsPerPage: this.itemsPerPage,
          confirmStatus: this.SearchConfirmStatus,
          status: this.SearchStatus,
          employeeId: this.selectedEmployee,
        },
      });
    },

    handleNotificationClick(postId, commentId, readId, index, leftMenuName) {
      console.log(
        "postId:",
        postId,
        "commentId:",
        commentId,
        "readId:",
        readId,
        "index:",
        index,
        "leftMenuName:",
        leftMenuName
      );
      if (!postId) {
        console.error("Post ID가 없습니다.");
        return;
      }

      // 1. 읽음 처리
      this.handleNotificationAction(readId, index).then(() => {
        // 2. 경로에 따라 라우터 이동
        if (leftMenuName === "프로젝트목록 (마이즈)") {
          this.$router.push({
            path: `/projects/mizeview/${postId}`,
            query: { commentId },
          });
        }

        if (leftMenuName === "프로젝트목록 (외주)") {
          this.$router.push({
            path: `/projects/view/${postId}`,
            query: { commentId },
          });
        }

        if (leftMenuName === "담당자목록") {
          this.$router.push({
            path: `/managers/view/${postId}`,
            query: { commentId },
          });
        }

        if (leftMenuName === "공지사항목록") {
          this.$router.push({
            path: `/notices/view/${postId}`,
            query: { commentId },
          });
        }

        // 3. 페이지 로드 후 스크롤 처리
        this.$nextTick(() => {
          setTimeout(() => {
            const commentElement = document.getElementById(
              `comment-${commentId}`
            );
            if (commentElement) {
              commentElement.scrollIntoView({ behavior: "smooth" });
            }
          }, 500);
        });
      });
    },

    selectEmployee(userId) {
      this.selectedEmployee = userId;
      this.currentPage = 1;
      this.updateQuery(); // query 업데이트
      this.fetchData();
    },
    async handleNotificationAction(notificationId, index) {
      try {
        const searchParam = {
          Page: 1,
          PageSize: 5,
          SearchConfirmStatus: "N", // 미확인 알림만
          SearchStatus: "A", // 전체 카테고리
          SearchBoardStatus: "", // 게시판 전체
          SearchEmployeeStatus: "A", // 모든 직원
          LoginUserId: localStorage.getItem("userid"),
          SearchUserId: this.selectedEmployee,
          ReadId: notificationId,
        };

        const response = await apiClient.post(
          "/api/Alarm/all-alarm-list",
          searchParam
        );

        if (response.status === 200) {
          // 알림 배열에서 제거
          this.alarmList.splice(index, 1);
          this.totalalarmNotices = Math.max(this.totalalarmNotices - 1, 0);
        }
      } catch (error) {
        console.error(
          "Error marking notification as read and removing:",
          error
        );
      }
    },
    // 상태에 따른 문구 반환
    getTopMenuText(status) {
      switch (status) {
        case "P":
          return "프로젝트관리";
        case "B":
          return "정산관리";
        case "M":
          return "담당자관리";
        case "N":
          return "공지사항관리";
        default:
          return "알 수 없음";
      }
    },
    updateConfirmStatus(status) {
      this.SearchConfirmStatus = status;
      this.currentPage = 1; // 상태 변경 시 페이지를 1로 리셋
      this.alarmList = []; //
      this.fetchData();
    },
    updateStatus(status) {
      this.SearchStatus = status;
      this.currentPage = 1; // 상태 변경 시 페이지를 1로 리셋
      this.alarmList = []; //
      this.fetchData();
    },
    updateBoardStatus(status) {
      this.SearchBoardStatus = status;
      this.currentPage = 1; // 상태 변경 시 페이지를 1로 리셋
      this.alarmList = []; //
      this.fetchData();
    },
    updateEmployeeStatus(status) {
      this.SearchEmployeeStatus = status;
      this.currentPage = 1; // 상태 변경 시 페이지를 1로 리셋
      this.alarmList = []; //
      this.fetchData();
    },
    getFormattedStartDate(startDate) {
      if (!startDate) {
        return "";
      }

      // 날짜 문자열을 '^'로 분리
      const dates = startDate.split("^");

      // 첫 번째 날짜는 그대로 표시하고, 나머지 날짜가 있다면 "(선택가능)" 추가
      if (dates.length > 1) {
        return `${dates[0]} (선택가능)`;
      }

      return dates[0]; // 첫 번째 날짜만 반환
    },

    handleSearchKeyEnter() {
      this.currentPage = 1; // itemsPerPage 변경 시 페이지를 1로 리셋
      this.alarmList = []; // 페이지 변경 시 목록 초기화
      this.fetchData();
    },

    toggleAllSelections() {
      if (this.allSelected) {
        this.selectedalarmNotices = this.alarmList.map(
          (notice) => notice.noticeId
        );
      } else {
        this.selectedalarmNotices = [];
      }
    },
    toggleSelection(noticeId) {
      const index = this.selectedalarmNotices.indexOf(noticeId);

      if (index > -1) {
        this.selectedalarmNotices.splice(index, 1);
      } else {
        this.selectedalarmNotices.push(noticeId); // 기존 코드를 이 줄로 수정
      }
    },
    handleItemsPerPageChange() {
      this.currentPage = 1; // itemsPerPage 변경 시 페이지를 1로 리셋
      this.updateQuery(); // query 업데이트
      this.alarmList = []; // 페이지 변경 시 목록 초기화
      this.fetchData();
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.alarmList = []; // 페이지 변경 시 목록 초기화
        this.fetchData(); // 페이지가 변경될 때마다 데이터 다시 가져오기
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.alarmList = []; // 페이지 변경 시 목록 초기화
        this.fetchData(); // 페이지가 변경될 때마다 데이터 다시 가져오기
      }
    },
    nextPageGroup() {
      this.currentPage = this.totalPages;
      this.alarmList = []; // 페이지 변경 시 목록 초기화
      this.fetchData(); // 페이지가 변경될 때마다 데이터 다시 가져오기
    },
    prevPageGroup() {
      this.currentPage = 1;
      this.alarmList = []; // 페이지 변경 시 목록 초기화
      this.fetchData(); // 페이지가 변경될 때마다 데이터 다시 가져오기
    },
    goToPage(page) {
      this.currentPage = page;
      this.updateQuery(); // query 업데이트
      this.alarmList = []; // 페이지 변경 시 목록 초기화
      this.fetchData();
    },
    async fetchData() {
      try {
        const searchParam = {
          Page: this.currentPage,
          PageSize: this.itemsPerPage,
          SearchConfirmStatus: this.SearchConfirmStatus, //--확인여부 // 전체 A, 미확인알림 N
          SearchStatus: this.SearchStatus, // --카테고리 // 전체 A, 프로젝트 P, 정산 B, 담당자 M, 공지사항 N
          SearchBoardStatus: this.SearchBoardStatus, // -- 게시판 // 메뉴명 그대로 값이 넘어올거임
          SearchEmployeeStatus: this.SearchEmployeeStatus, //-- 직원별 // 전체 A,  user_id 가 넘어올거임
          SearchUserId: this.selectedEmployee,
          LoginUserId: localStorage.getItem("userid"),
        };

        const response = await apiClient.post(
          "/api/Alarm/all-alarm-list",
          searchParam
        );

        if (response.status === 200) {
          console.log(response.data);
          const data = JSON.parse(response.data);
          this.alarmList = Array.isArray(data.data) ? data.data : [];
          console.log("---alarm---" + this.alarmList);
          this.totalalarmNotices = data.meta.totalCount || 0;
        }
      } catch (error) {
        console.error("Error fetching alarmnotices list data:", error);
      }
    },

    async fetchEmployees() {
      try {
        const searchParam = {
          PageSize: 100,
          Page: "1",
        };

        const response = await apiClient.post(
          "/api/User/all-employee-list",
          searchParam
        ); // 예시 API 엔드포인트
        if (response.status === 200) {
          const data = JSON.parse(response.data);
          this.salesEmployees = Array.isArray(data.data) ? data.data : [];
          console.log(this.salesEmployees);
        }
      } catch (error) {
        console.error("Error fetching sales managers:", error);
      }
    },
  },
  mounted() {
    const { page, itemsPerPage, confirmStatus, status, employeeId } =
      this.$route.query;

    this.currentPage = page ? parseInt(page) : 1;
    this.itemsPerPage = itemsPerPage ? parseInt(itemsPerPage) : 10;
    this.SearchConfirmStatus = confirmStatus || "N";
    this.SearchStatus = status || "A";
    this.selectedEmployee = employeeId || "All";

    this.fetchEmployees(); // 영업 담당자 목록을 가져옴
    this.fetchData();
  },
};
</script>

<style>
.disabled-row {
  background-color: #f0f0f0; /* 어두운 배경 */
  color: #a0a0a0; /* 희미한 글씨 색 */
  pointer-events: none; /* 클릭 비활성화 */
  cursor: not-allowed; /* 클릭 금지 커서 */
}
</style>
